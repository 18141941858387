import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';
import { useNavigate, NavLink, Navigate } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import noImg from "../../../Assets/Images/FMS/vessel_fms_icon.png";
import FMSVesselSMSDashboard from '../../../v2/Pages/FMSVessel/VesselSMSDashboard';
import FMSVesselTrackingPage from '../../../v2/Pages/FMSVessel/VesselTracking';
import FMSVesselDetailPage from '../../../v2/Pages/FMSVessel/VesselDetail';
import FMSVesselDocumentPage from '../../../v2/Pages/FMSVessel/VesselDocument';
import FMSVesselEquipmentPage from '../../../v2/Pages/FMSVessel/VesselEquipment';
import moment from "moment";

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{
      backgroundColor: '#1E3ABA',
      '& .MuiTableCell-root': {
        color: '#64748b',
      }
    }}>
      <TableRow>
        <TableCell padding="checkbox"
        sx={{backgroundColor: '#FFF'}}>
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all rows',
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            sx={{backgroundColor: '#FFF'}}
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {
              headCell.id === 'img_button' ? (<></>) : (<TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>)
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  return (
    <Toolbar
      sx={[
        {
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        },
        numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        },
      ]}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >

        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            {/* <DeleteIcon /> */}
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function EnhancedTable({ data, columns, setPageToDisplay, setPageTitle }) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(columns[0].id);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filterText, setFilterText] = React.useState('');
  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const filteredData = React.useMemo(() => {
    return data.filter((row) =>
      columns.some((column) =>
        String(row[column.id]).toLowerCase().includes(filterText.toLowerCase())
      )
    );
  }, [data, filterText, columns]);

  const visibleRows = React.useMemo(() => {
    return filteredData
      .sort((a, b) => {
        const compareA = a[orderBy];
        const compareB = b[orderBy];
        if (compareA < compareB) return order === 'asc' ? -1 : 1;
        if (compareA > compareB) return order === 'asc' ? 1 : -1;
        return 0;
      })
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [filteredData, order, orderBy, page, rowsPerPage]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mb: 2, pt:1 }}>
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          size='small'
          sx={{width: '300px'}}
        />
      </Box>
      <Paper sx={{ width: '100%', mb: 2 }}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={(e) =>
                setSelected(
                  e.target.checked
                    ? filteredData.map((n) => n.id)
                    : []
                )
              }
              onRequestSort={handleRequestSort}
              rowCount={filteredData.length}
              headCells={columns}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = selected.includes(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      {/* <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      /> */}
                    </TableCell>
                    {columns.map((column) => (
                      <TableCell key={column.id} align={'center'}>
                        {
                          column.id === 'maintenance_button' ? (<Button style={{ marginRight: '15px' }} onClick={() => {
                            // navigate('/')
                          }}><Icon size={40} color="#64748B">engineering</Icon></Button>) :
                            (column.id === 'shipparticular_button' ? (<Button style={{ marginRight: '15px' }} onClick={() => {
                              // navigate('/FMS/Vessel/VesselDetail', { state: { vesselId: row.id } })
                              setPageToDisplay(<FMSVesselDetailPage state={{vesselId: row.id}} />);
                              setPageTitle(`${row?.vessel_name || "VESSEL"} | ${row?.imo_number} DETAIL`);
                            }}><Icon size={40} color="#64748B">directions_boat</Icon></Button>) :
                              (column.id === 'equipment_button' ? (<Button style={{ marginRight: '15px' }} onClick={() => {
                                setPageToDisplay(<FMSVesselEquipmentPage state={{vesselId: row.id}} />);
                                setPageTitle(`${row?.vessel_name || "VESSEL"} | ${row?.imo_number} EQUIPMENT`);
                              }}><Icon size={40} color="#64748B">engineering</Icon></Button>) :
                                (column.id === 'certificate_button' ? (<Button style={{ marginRight: '15px' }} onClick={() => {
                                  // navigate('/')
                                  setPageToDisplay(<FMSVesselDocumentPage state={{vesselId: row.id, vesselName: row.vessel_name}} />);
                                  setPageTitle(`${row?.vessel_name || "VESSEL"} | ${row?.imo_number} DOCUMENT`);
                                }}><Icon size={40} color="#64748B">description</Icon></Button>) :
                                  (column.id === 'tracking_button' ? (<Button style={{ marginRight: '15px' }} onClick={() => {
                                    // navigate('/FMS/Vessel/VesselTracking', { state: { vesselId: row.id } })
                                    setPageToDisplay(<FMSVesselTrackingPage state={{vesselId: row.id, vesselName: row.vessel_name}} />);
                                    setPageTitle(`${row?.vessel_name || "VESSEL"} | ${row?.imo_number} TRACKING`);
                                  }}><Icon size={40} color="#64748B">pin_drop</Icon></Button>) : (column.id === 'smsdashboard_button' ? (<Button style={{ marginRight: '15px' }} onClick={() => {
                                    // navigate('/FMS/Vessel/Dashboard', { state: { vesselId: row.id } })
                                    setPageToDisplay(<FMSVesselSMSDashboard state={{vesselId: row.id}} />);
                                    setPageTitle(`${row?.vessel_name || "DASHBOARD"} | ${row?.imo_number || "VESSEL"}`);
                                  }}><Icon size={40} color="#64748B">speed</Icon></Button>) : (column.id === 'vessel_status' ? (<Button style={{marginRight: '15px'}}><Box sx={{ backgroundColor: moment().diff(moment.utc(row.received_date).local(), 'minutes') <= 10  ? 'green' : 'red', borderRadius: '50%'}} width={25} height={25}></Box></Button>) : 
                                    (column.id === 'img_button' ? (
                                      <div style={{ flex: 1, padding: '5px', width: '100px', height: '100px', display: 'flex', justifyContent: "center", alignItems: 'center', border: '1px #bebebe solid', borderRadius: '5px' }}>
                                        <LazyLoadImage
                                          src={row.base64_image !== null ? row.base64_image : noImg}
                                          style={{
                                            display: { xs: 'none', md: 'flex' },
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover'
                                          }}
                                        />
                                      </div>) : (row[column.id]))))))))
                        }
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, newPage) => setPage(newPage)}
          onRowsPerPageChange={(e) =>
            setRowsPerPage(parseInt(e.target.value, 10))
          }
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={(e) => setDense(e.target.checked)} />}
        label="Dense padding"
      /> */}
    </Box>
  );
}

EnhancedTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
};

export default EnhancedTable;
