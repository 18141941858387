import React, { useEffect, useState, Children } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMap, rotatedMarker } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import L, { marker } from "leaflet";
import vesselImg from "../../../Assets/Images/new_arrow_pointer.png";
import noImg from "../../../Assets/Images/FMS/noImg.jpg";
// import vesselImg from "../Assets/Images/ship-solid-red-resized.png";
// import { Button } from "react-bootstrap";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import 'leaflet-rotatedmarker';
import 'leaflet-ant-path';
import { Box, Card, Button, Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import Collapse from '@mui/material/Collapse';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

export default function FMSOpenSeaMapTracking({ markerChild, isTracking = false }) {
    const [vesselName, setVesselName] = useState();
    const [companyName, setCompanyName] = useState();
    const [vesselType, setVesselType] = useState();
    const [locationData, setLocationData] = useState();
    const [defaultPosition, setDefaultPosition] = useState([-6.12747410563145, 106.85523275294094]);
    const [checked, setChecked] = useState(false);
    const [mostRecentWeatherMap, setMostRecentWeatherMap] = useState();
    const [cog, setCog] = useState(0);
    const [, forceUpdate] = React.useReducer(x => x + 1, 0)

    // const RADAR_MAPS_URL = "https://api.rainviewer.com/public/weather-maps.json";
   
    useEffect(() => {
        // console.log('cog', cog);

    }, [cog]);

    useEffect(() => {
        //getMostRecentWeatherMap();
    }, [])
    
    const CenterMap = ({ position }) => {
        const map = useMap();
        useEffect(() => {
            if (position) {
                map.setView(position, 15);
            }
        }, [map, position]);
        return null;
    };

    useEffect(() => {
        if (markerChild?.listMarker?.length > 0) {
            const firstMarker = markerChild.listMarker[0].props.position;
            setDefaultPosition(firstMarker);
        }
    }, [markerChild]);

    let vesselIcon = L.icon({
        iconUrl: vesselImg,
        iconRetinaUrl: vesselImg,
        popupAnchor: [0, -30],
        iconSize: [30, 30],
    });

    // const getMostRecentWeatherMap = async () => {
    //     const res = await fetch(RADAR_MAPS_URL);
    //     // console.log(res);
    //     // const resJson = (await res.json());
    //     // return resJson.radar.nowcast[0].path;
    // };

    const AntPathComponent = ({ marker }) => {
        if (isTracking) {
            // console.log(marker);
            const map = useMap();
            let route = marker.map(marker => marker.props.position);
            useEffect(() => {
                const path = L.polyline.antPath(route, {
                    delay: 2000,
                    dashArray: [20, 20],
                    weight: 2,
                    color: "#0000FF",
                    pulseColor: "#FFFFFF",
                    paused: false,
                    reverse: false,
                    hardwareAccelerated: true
                });

                path.addTo(map);
                // map.fitBounds(path.getBounds());

                return () => {
                    map.removeLayer(path);
                };

            }, [map, route]);
        } else {
            // console.log(marker);
            marker.forEach((markerEl) => {
                const map = useMap();
                let route = marker.map(marker => marker.props.position);
                useEffect(() => {
                    const path = L.polyline.antPath(route, {
                        delay: 800,
                        dashArray: [20, 20],
                        weight: 2,
                        color: "#0000FF",
                        pulseColor: "#FFFFFF",
                        paused: false,
                        reverse: false,
                        hardwareAccelerated: true
                    });

                    path.addTo(map);
                    // map.fitBounds(path.getBounds());

                    return () => {
                        map.removeLayer(path);
                    };

                }, [map, route]);
            });

        }

        return null;
    };


    const ZoomToMarkerButton = ({ position }) => {
        const map = useMap();

        const zoomToMarker = () => {

            map.setView([position.latitude, position.longitude], 20);
        };

        return (
            // <Box
            //                     sx={{
            //                         width: 40,
            //                         height: 40,
            //                         borderRadius: 1,
            //                         bgcolor: '#1E3A8A',
            //                         boxShadow: 5
            //                     }}
            //                 >
                                <IconButton aria-label="delete" size="small" sx={{
                                    color: '#1E3A8A'
                                }} onClick={zoomToMarker}>
                                    <RemoveRedEyeIcon fontSize="8px" />
                                </IconButton>
                            // </Box>

        );
    };

    const renderListMarker = () => {
        if (markerChild) {
            if (markerChild.listMarker) {
                return (markerChild.listMarker);
            }
        }
    }

    const renderAnPath = () => {

        // console.log(markerChild);
        if (markerChild) {
            if (markerChild.listLine) {
                return <AntPathComponent marker={markerChild.listLine} />
            }
        }
    }

    const handleChange = () => {
        setChecked((prev) => !prev);
    };


    return (
        <>
            <MapContainer style={{ flex: 1, width: '100%' }} preferCanvas={true} center={defaultPosition} zoom={15} scrollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <CenterMap position={defaultPosition} />
                {renderListMarker()}
                {/* {renderPolyline()} */}
                {/* {renderListVessel()} */}

                {renderAnPath()}
                

                {/* <TileLayer
                    attribution="RainViewer.com"
                    url={`https://tilecache.rainviewer.com${mostRecentWeatherMap}/256/{z}/{x}/{y}/2/1_1.png`}
                    opacity={0.6}
                    zIndex={2}
                /> */}

            </MapContainer>
        </>
    );
}
