import './index.css';
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getSMSEnginesValue } from "../../../../Helpers/ApplicationHelpers";
import "../../../AppV2.css";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import FMSTheme from "../../../FMSTheme";
import { Trans, useTranslation } from "react-i18next";
import NavBar from "../../../../Components/v2/FMSNavBarMUI";
import { Box, TextField, Typography, Tabs, Tab, Divider } from '@mui/material';
import Container from 'react-bootstrap/Container';
import GaugeComponent from '../../../../Components/v2/FMSGauge';
import ProgressBar from '../../../../Components/v2/FMSProgressBar';
import BitLampNew from '../../../../Components/v2/FMSBitLamp/index';

export default function FMSVesselSMSPage() {
    const { t, i18n } = useTranslation();
    const [cookies, removeCookie] = useCookies(["token"]);
    const [vesselId, setVesselId] = useState();
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    let location = useLocation();
    const [gaugeValue, setGaugeValue] = useState(50);
    const [engineSMSValue, setEngineSMSValue] = useState({})
    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                if (cookies.language_code === "id-id") {
                    i18n.changeLanguage("id");
                } else if (cookies.language_code === "en-us") {
                    i18n.changeLanguage("en");
                }

                if (location.state.vesselId) {
                    setVesselId(location.state.vesselId);

                } else {
                    navigate('/FMS');
                }
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (vesselId) {
            loadEnginesValue();
        }
    }, [vesselId])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }

    useEffect(() => {
        if (engineSMSValue) {
            console.log('engineSMSValue', engineSMSValue);
        }
    }, [engineSMSValue])

    const loadEnginesValue = async () => {
        try {
            let result = await getSMSEnginesValue(cookies.token, vesselId);
            setEngineSMSValue(result);
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const MEPComponent = ({ mepValue }) => {
        // console.log(mepValue);
        if (mepValue !== undefined) {
            const memoizedChild = useMemo(() => {
                return (
                    <div className='tabPanel0Container-left'>
                        <div style={{ width: '100%', textAlign: 'left', margin: 0 }}>
                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 20, fontWeight: 'bolder' }}>
                                PORT
                            </Typography>
                            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                TYPE XXXXX
                            </Typography>
                            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                S/N: XXXXX
                            </Typography>
                        </div>
                        <div className='fmsGaugeComponentContainer'>
                            <GaugeComponent value={mepValue.rpm ? mepValue.rpm.reading_value : '0'}
                                size={350} minValue={mepValue.rpm ? mepValue.rpm.min_value : '0'}
                                maxValue={mepValue.rpm ? mepValue.rpm.max_value : '0'}
                                valueUnit={mepValue.rpm ? mepValue.rpm.value_unit : ''} />
                        </div>
                        <div className='fmsProgressBarComponentContainerThrottle'>
                            <Typography variant="body2" sx={{ color: 'text.primary', fontSize: 15, fontWeight: 'bolder' }}>
                                {'THROTTLE'}
                            </Typography>
                            <ProgressBar
                                value={mepValue.fuelRack.reading_value !== undefined ? parseFloat(mepValue.fuelRack.reading_value) : 0}
                                height={30}
                                minValue={mepValue.fuelRack.minValue !== undefined ? parseFloat(mepValue.fuelRack.min_value) : 0}
                                maxValue={mepValue.fuelRack.maxValue !== undefined ? parseFloat(mepValue.fuelRack.max_value) : 100}
                                redRange={[95, 100]}
                                yellowRange={[80, 96]}
                                greenRange={[0, 79]}
                                valueUnit={mepValue.fuelRack.value_unit !== undefined ? mepValue.fuelRack.value_unit : ''} />
                        </div>
                        <div className='progressBarContainerFMS'>
                            <div className='fmsProgressBarComponentContainer'>
                                <Typography variant="body2" sx={{ color: 'text.primary', fontSize: 15, fontWeight: 'bolder' }}>
                                    OIL PRESSURE
                                </Typography>
                                <ProgressBar
                                    value={mepValue.oilPressure.reading_value !== undefined ? parseFloat(mepValue.oilPressure.reading_value) : 0}
                                    height={20}
                                    minValue={mepValue.oilPressure.minValue !== undefined ? parseFloat(mepValue.oilPressure.min_value) : 0}
                                    maxValue={mepValue.oilPressure.maxValue !== undefined ? parseFloat(mepValue.oilPressure.max_value) : 100}
                                    redRange={[95, 100]}
                                    yellowRange={[80, 96]}
                                    greenRange={[0, 79]}
                                    valueUnit={mepValue.oilPressure.value_unit !== undefined ? mepValue.oilPressure.value_unit : ''} />
                            </div>
                            <div className='fmsProgressBarComponentContainer'>
                                <Typography variant="body2" sx={{ color: 'text.primary', fontSize: 15, fontWeight: 'bolder' }}>
                                    CLUTCH OIL PRESSURE
                                </Typography>
                                <ProgressBar
                                    value={mepValue.clutchOilPressure.reading_value !== undefined ? parseFloat(mepValue.clutchOilPressure.reading_value) : 0}
                                    height={20}
                                    minValue={mepValue.clutchOilPressure.minValue !== undefined ? parseFloat(mepValue.clutchOilPressure.min_value) : 0}
                                    maxValue={mepValue.clutchOilPressure.maxValue !== undefined ? parseFloat(mepValue.clutchOilPressure.max_value) : 100}
                                    redRange={[95, 100]}
                                    yellowRange={[80, 96]}
                                    greenRange={[0, 79]}
                                    valueUnit={mepValue.clutchOilPressure.value_unit !== undefined ? mepValue.clutchOilPressure.value_unit : ''} />
                            </div>
                            <div className='fmsProgressBarComponentContainer'>
                                <Typography variant="body2" sx={{ color: 'text.primary', fontSize: 15, fontWeight: 'bolder' }}>
                                    BOOST PRESSURE
                                </Typography>
                                <ProgressBar
                                    value={mepValue.boostPressure.reading_value !== undefined ? parseFloat(mepValue.boostPressure.reading_value) : 0}
                                    height={20}
                                    minValue={mepValue.boostPressure.minValue !== undefined ? parseFloat(mepValue.boostPressure.min_value) : 0}
                                    maxValue={mepValue.boostPressure.maxValue !== undefined ? parseFloat(mepValue.boostPressure.max_value) : 100}
                                    redRange={[95, 100]}
                                    yellowRange={[80, 96]}
                                    greenRange={[0, 79]}
                                    valueUnit={mepValue.boostPressure.value_unit !== undefined ? mepValue.boostPressure.value_unit : ''} />
                            </div>
                            <div className='fmsProgressBarComponentContainer'>
                                <Typography variant="body2" sx={{ color: 'text.primary', fontSize: 15, fontWeight: 'bolder' }}>
                                    COOLING WATER
                                </Typography>
                                <ProgressBar
                                    value={mepValue.coolingWater.reading_value !== undefined ? parseFloat(mepValue.coolingWater.reading_value) : 0}
                                    height={20}
                                    minValue={mepValue.coolingWater.minValue !== undefined ? parseFloat(mepValue.coolingWater.min_value) : 0}
                                    maxValue={mepValue.coolingWater.maxValue !== undefined ? parseFloat(mepValue.coolingWater.max_value) : 100}
                                    redRange={[95, 100]}
                                    yellowRange={[80, 96]}
                                    greenRange={[0, 79]}
                                    valueUnit={mepValue.coolingWater.value_unit !== undefined ? mepValue.coolingWater.value_unit : ''} />
                            </div>
                            <div className='fmsProgressBarComponentContainer'>
                                <Typography variant="body2" sx={{ color: 'text.primary', fontSize: 15, fontWeight: 'bolder' }}>
                                    EXHAUST TEMPERATURE
                                </Typography>
                                <ProgressBar
                                    value={mepValue.exhaustTemperature.reading_value !== undefined ? parseFloat(mepValue.exhaustTemperature.reading_value) : 0}
                                    height={20}
                                    minValue={mepValue.exhaustTemperature.minValue !== undefined ? parseFloat(mepValue.exhaustTemperature.min_value) : 0}
                                    maxValue={mepValue.exhaustTemperature.maxValue !== undefined ? parseFloat(mepValue.exhaustTemperature.max_value) : 100}
                                    redRange={[95, 100]}
                                    yellowRange={[80, 96]}
                                    greenRange={[0, 79]}
                                    valueUnit={mepValue.exhaustTemperature.value_unit !== undefined ? mepValue.exhaustTemperature.value_unit : ''} />
                            </div>
                        </div>

                    </div>
                )
            }, [mepValue]);
            return <>
                {memoizedChild}
            </>;
        }

    }

    const MESComponent = ({ mesValue }) => {
        // console.log(mesValue);
        if (mesValue !== undefined) {
            const memoizedChild = useMemo(() => {
                return (
                    <div className='tabPanel0Container-right'>
                        <div style={{ width: '100%', textAlign: 'right', margin: 0 }}>
                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 20, fontWeight: 'bolder' }}>
                                STARBOARD
                            </Typography>
                            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                TYPE XXXXX
                            </Typography>
                            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                S/N: XXXXX
                            </Typography>
                        </div>
                        <div className='fmsGaugeComponentContainer'>
                            <GaugeComponent value={mesValue.rpm ? mesValue.rpm.reading_value : '0'}
                                size={350} minValue={mesValue.rpm ? mesValue.rpm.min_value : '0'}
                                maxValue={mesValue.rpm ? mesValue.rpm.max_value : '0'}
                                valueUnit={mesValue.rpm ? mesValue.rpm.value_unit : ''} />
                        </div>
                        <div className='fmsProgressBarComponentContainerThrottle'>
                            <Typography variant="body2" sx={{ color: 'text.primary', fontSize: 15, fontWeight: 'bolder' }}>
                                {'THROTTLE'}
                            </Typography>
                            <ProgressBar
                                value={mesValue.fuelRack.reading_value !== undefined ? parseFloat(mesValue.fuelRack.reading_value) : 0}
                                height={30}
                                minValue={mesValue.fuelRack.minValue !== undefined ? parseFloat(mesValue.fuelRack.min_value) : 0}
                                maxValue={mesValue.fuelRack.maxValue !== undefined ? parseFloat(mesValue.fuelRack.max_value) : 100}
                                redRange={[95, 100]}
                                yellowRange={[80, 96]}
                                greenRange={[0, 79]}
                                valueUnit={mesValue.fuelRack.value_unit !== undefined ? mesValue.fuelRack.value_unit : ''} />
                        </div>
                        <div className='progressBarContainerFMS'>
                            <div className='fmsProgressBarComponentContainer'>
                                <Typography variant="body2" sx={{ color: 'text.primary', fontSize: 15, fontWeight: 'bolder' }}>
                                    OIL PRESSURE
                                </Typography>
                                <ProgressBar
                                    value={mesValue.oilPressure.reading_value !== undefined ? parseFloat(mesValue.oilPressure.reading_value) : 0}
                                    height={20}
                                    minValue={mesValue.oilPressure.minValue !== undefined ? parseFloat(mesValue.oilPressure.min_value) : 0}
                                    maxValue={mesValue.oilPressure.maxValue !== undefined ? parseFloat(mesValue.oilPressure.max_value) : 100}
                                    redRange={[95, 100]}
                                    yellowRange={[80, 96]}
                                    greenRange={[0, 79]}
                                    valueUnit={mesValue.oilPressure.value_unit !== undefined ? mesValue.oilPressure.value_unit : ''} />
                            </div>
                            <div className='fmsProgressBarComponentContainer'>
                                <Typography variant="body2" sx={{ color: 'text.primary', fontSize: 15, fontWeight: 'bolder' }}>
                                    CLUTCH OIL PRESSURE
                                </Typography>
                                <ProgressBar
                                    value={mesValue.clutchOilPressure.reading_value !== undefined ? parseFloat(mesValue.clutchOilPressure.reading_value) : 0}
                                    height={20}
                                    minValue={mesValue.clutchOilPressure.minValue !== undefined ? parseFloat(mesValue.clutchOilPressure.min_value) : 0}
                                    maxValue={mesValue.clutchOilPressure.maxValue !== undefined ? parseFloat(mesValue.clutchOilPressure.max_value) : 100}
                                    redRange={[95, 100]}
                                    yellowRange={[80, 96]}
                                    greenRange={[0, 79]}
                                    valueUnit={mesValue.clutchOilPressure.value_unit !== undefined ? mesValue.clutchOilPressure.value_unit : ''} />
                            </div>
                            <div className='fmsProgressBarComponentContainer'>
                                <Typography variant="body2" sx={{ color: 'text.primary', fontSize: 15, fontWeight: 'bolder' }}>
                                    BOOST PRESSURE
                                </Typography>
                                <ProgressBar
                                    value={mesValue.boostPressure.reading_value !== undefined ? parseFloat(mesValue.boostPressure.reading_value) : 0}
                                    height={20}
                                    minValue={mesValue.boostPressure.minValue !== undefined ? parseFloat(mesValue.boostPressure.min_value) : 0}
                                    maxValue={mesValue.boostPressure.maxValue !== undefined ? parseFloat(mesValue.boostPressure.max_value) : 100}
                                    redRange={[95, 100]}
                                    yellowRange={[80, 96]}
                                    greenRange={[0, 79]}
                                    valueUnit={mesValue.boostPressure.value_unit !== undefined ? mesValue.boostPressure.value_unit : ''} />
                            </div>
                            <div className='fmsProgressBarComponentContainer'>
                                <Typography variant="body2" sx={{ color: 'text.primary', fontSize: 15, fontWeight: 'bolder' }}>
                                    COOLING WATER
                                </Typography>
                                <ProgressBar
                                    value={mesValue.coolingWater.reading_value !== undefined ? parseFloat(mesValue.coolingWater.reading_value) : 0}
                                    height={20}
                                    minValue={mesValue.coolingWater.minValue !== undefined ? parseFloat(mesValue.coolingWater.min_value) : 0}
                                    maxValue={mesValue.coolingWater.maxValue !== undefined ? parseFloat(mesValue.coolingWater.max_value) : 100}
                                    redRange={[95, 100]}
                                    yellowRange={[80, 96]}
                                    greenRange={[0, 79]}
                                    valueUnit={mesValue.coolingWater.value_unit !== undefined ? mesValue.coolingWater.value_unit : ''} />
                            </div>
                            <div className='fmsProgressBarComponentContainer'>
                                <Typography variant="body2" sx={{ color: 'text.primary', fontSize: 15, fontWeight: 'bolder' }}>
                                    EXHAUST TEMPERATURE
                                </Typography>
                                <ProgressBar
                                    value={mesValue.exhaustTemperature.reading_value !== undefined ? parseFloat(mesValue.exhaustTemperature.reading_value) : 0}
                                    height={20}
                                    minValue={mesValue.exhaustTemperature.minValue !== undefined ? parseFloat(mesValue.exhaustTemperature.min_value) : 0}
                                    maxValue={mesValue.exhaustTemperature.maxValue !== undefined ? parseFloat(mesValue.exhaustTemperature.max_value) : 100}
                                    redRange={[95, 100]}
                                    yellowRange={[80, 96]}
                                    greenRange={[0, 79]}
                                    valueUnit={mesValue.exhaustTemperature.value_unit !== undefined ? mesValue.exhaustTemperature.value_unit : ''} />
                            </div>
                        </div>

                    </div>
                )
            }, [mesValue]);
            return <>
                {memoizedChild}
            </>;
        }

    }

    const AEPComponent = ({ aepValue }) => {
        // console.log(aepValue);
        if (aepValue !== undefined) {
            const memoizedChild = useMemo(() => {
                return (
                    <div className='tabPanel0Container-left'>
                        <div style={{ width: '100%', textAlign: 'left', margin: 0 }}>
                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 20, fontWeight: 'bolder' }}>
                                PORT
                            </Typography>
                            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                TYPE XXXXX
                            </Typography>
                            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                S/N: XXXXX
                            </Typography>
                        </div>
                        <div className='fmsGaugeComponentContainer'>
                            <GaugeComponent value={aepValue.rpm ? aepValue.rpm.reading_value : 0}
                                size={350} minValue={aepValue.rpm ? aepValue.rpm.min_value : 0}
                                maxValue={aepValue.rpm ? aepValue.rpm.max_value : 0}
                                valueUnit={aepValue.rpm ? aepValue.rpm.value_unit : ''} />
                        </div>
                        <div className='progressBarContainerFMS'>
                            <div className='fmsBitLampComponentContainer'>
                                <BitLampNew alarmValue={aepValue.overSpeed.reading_value !== undefined ? aepValue.overSpeed.reading_value : 'False'} bitLampTitle={'OVER SPEED'} />
                            </div>
                            <div className='fmsBitLampComponentContainer'>
                                <BitLampNew alarmValue={aepValue.oilPressSwitch.reading_value !== undefined ? aepValue.oilPressSwitch.reading_value : 'False'} bitLampTitle={'OIL PRESSURE SWITCH'} />
                            </div>
                            <div className='fmsBitLampComponentContainer'>
                                <BitLampNew alarmValue={aepValue.coolantTemperature.reading_value !== undefined ? aepValue.coolantTemperature.reading_value : 'False'} bitLampTitle={'COOLANT TEMPERATURE'} />
                            </div>
                            <div className='fmsBitLampComponentContainer'>
                                <BitLampNew alarmValue={aepValue.commonAlarm.reading_value !== undefined ? aepValue.commonAlarm.reading_value : 'False'} bitLampTitle={'COMMON ALARM'} />
                            </div>
                        </div>

                    </div>
                )
            }, [aepValue]);
            return <>
                {memoizedChild}
            </>;
        }

    }

    const AESComponent = ({ aesValue }) => {
        // console.log(aesValue);
        if (aesValue !== undefined) {
            const memoizedChild = useMemo(() => {
                return (
                    <div className='tabPanel0Container-left'>
                        <div style={{ width: '100%', textAlign: 'right', margin: 0 }}>
                            <Typography gutterBottom sx={{ color: 'text.primary', fontSize: 20, fontWeight: 'bolder' }}>
                                STARBOARD
                            </Typography>
                            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                TYPE XXXXX
                            </Typography>
                            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                S/N: XXXXX
                            </Typography>
                        </div>
                        <div className='fmsGaugeComponentContainer'>
                            <GaugeComponent value={aesValue.rpm ? aesValue.rpm.reading_value : 0}
                                size={350} minValue={aesValue.rpm ? aesValue.rpm.min_value : 0}
                                maxValue={aesValue.rpm ? aesValue.rpm.max_value : 0}
                                valueUnit={aesValue.rpm ? aesValue.rpm.value_unit : ''} />
                        </div>
                        <div className='progressBarContainerFMS'>
                            <div className='fmsBitLampComponentContainer'>
                                <BitLampNew alarmValue={aesValue.overSpeed.reading_value !== undefined ? aesValue.overSpeed.reading_value : 'False'} bitLampTitle={'OVER SPEED'} />
                            </div>
                            <div className='fmsBitLampComponentContainer'>
                                <BitLampNew alarmValue={aesValue.oilPressSwitch.reading_value !== undefined ? aesValue.oilPressSwitch.reading_value : 'False'} bitLampTitle={'OIL PRESSURE SWITCH'} />
                            </div>
                            <div className='fmsBitLampComponentContainer'>
                                <BitLampNew alarmValue={aesValue.coolantTemperature.reading_value !== undefined ? aesValue.coolantTemperature.reading_value : 'False'} bitLampTitle={'COOLANT TEMPERATURE'} />
                            </div>
                            <div className='fmsBitLampComponentContainer'>
                                <BitLampNew alarmValue={aesValue.commonAlarm.reading_value !== undefined ? aesValue.commonAlarm.reading_value : 'False'} bitLampTitle={'COMMON ALARM'} />
                            </div>
                        </div>

                    </div>
                )
            }, [aesValue]);
            return <>
                {memoizedChild}
            </>;
        }

    }

    return (
        <>
            <ThemeProvider theme={FMSTheme}>
                <div className='mainContainerFMS'>
                    <NavBar />
                    <div className='childMainContainerFMS'>
                        <div className='childMainContainerFMS-TOP'>
                            <p className='ptitleFMSDashboard'>SMART MONITORING SYSTEM</p>
                        </div>
                        <div className='childMainContainerFMS-BODY overflow-auto'>
                            <Box
                                sx={{ bgcolor: 'background.paper', display: 'flex', width: '100%', border: 1, borderColor: 'divider' }}
                            >
                                <Tabs
                                    orientation="vertical"
                                    variant="scrollable"
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="Vertical tabs example"
                                    sx={{ borderRight: 1, borderColor: 'divider' }}
                                >
                                    <Tab label="Main Engine" {...a11yProps(0)} />
                                    <Tab label="Auxiliary  Engine" {...a11yProps(1)} />
                                    <Tab label="Fuel Usage" {...a11yProps(2)} />
                                    <Tab label="Navigation" {...a11yProps(3)} />
                                    <Tab label="Tank Level" {...a11yProps(4)} />
                                </Tabs>
                                <Container fluid={true} className="">
                                    <TabPanel value={value} index={0}>
                                        <div className='tabPanel0Container'>
                                            {/* <MEPComponent mepValue={engineSMSValue && engineSMSValue.MEP} />
                                            <MESComponent mesValue={engineSMSValue && engineSMSValue.MES} /> */}
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <div className='tabPanel0Container'>
                                            {/* <AEPComponent aepValue={engineSMSValue && engineSMSValue.AEP} />
                                            <AESComponent aesValue={engineSMSValue && engineSMSValue.AES} /> */}
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        Item Three
                                    </TabPanel>
                                    <TabPanel value={value} index={3}>
                                        Item Four
                                    </TabPanel>
                                    <TabPanel value={value} index={4}>
                                        Item Five
                                    </TabPanel>
                                </Container>
                            </Box>



                            {/* <EnhancedTable data={listVessel} columns={columnsForTable} /> */}

                        </div>
                    </div>
                </div>
            </ThemeProvider>
        </>
    );
}