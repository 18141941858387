import './index.css';
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isFMSAdminTokenValid } from "../../../../Helpers/ApplicationHelpers";
import "../../../AppV2.css";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import FMSTheme from "../../../FMSTheme";
import { Trans, useTranslation } from "react-i18next";
import NavBar from "../../../../Components/v2/FMSAdminNavBarMUI";
import FMSAdminAuthenticateToken from '../../../../Components/v2/FMSAdminAuthenticateToken';
import { Button, Container, Icon} from '@mui/material';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, TextField, Paper } from '@mui/material';
import { getCustomerV2 } from "../../../../Helpers/ApplicationHelpers";
import AdminCustuomerVesselsPage from './CustomerVessels';


export default function AdminCustomerPage({setPageToDisplay}) {
    const { t, i18n } = useTranslation();
    const [cookies, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate();

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0); // current page
    const [pageSize, setPageSize] = useState(10); // rows per page
    const [filterText, setFilterText] = useState(''); // filter text for customer name
    const [totalRows, setTotalRows] = useState(0); // total number of rows for pagination


    const columns = [
        { label: 'Customer Name', field: 'customerName' },
        { label: 'Customer Vessels', field: 'customerVessels' },

        // Add other columns here as needed
    ];

    // Function to fetch data based on page, pageSize, and filterText
    const fetchData = async (page, pageSize, searchQuery) => {
        try {
            const result = await getCustomerV2(cookies.token, cookies.extCustomerId, searchQuery, page, pageSize);
            setRows(result.rows); 
            setTotalRows(result.totalRows);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Fetch data on initial load and whenever parameters change
    useEffect(() => {
        fetchData(page, pageSize, {"customer_name" : filterText});
    }, [page, pageSize, filterText]);

    // Handle page change
    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    // Handle page size change
    const handleRowsPerPageChange = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page when page size changes
    };

    // Handle filter input change
    const handleFilterChange = (event) => {
        setFilterText(event.target.value);
        setPage(0); // Reset to first page when filter changes
    };


    return (
        <>
            <div className='mainContainerFMS'>
                <div className='childMainContainerFMS overflow-auto'>
                    <h2>CUSTOMEeeR</h2>'
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TextField
                            label="Search by Customer Name"
                            variant="outlined"
                            value={filterText || ""}
                            onChange={handleFilterChange}
                            size="small"
                            style={{ marginBottom: '20px', width: '300px' }}
                        />
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell key={column.field}>{column.label}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows && rows.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.customer_name}</TableCell>
                                        <TableCell>
                                            <Button style={{ marginRight: '15px' }} onClick={() => {
                                                setPageToDisplay(<AdminCustuomerVesselsPage state={{customerId: row.customer_id}} setPageToDisplay={setPageToDisplay}/>);
                                            }}><Icon size={40} color="#64748B">speed</Icon>
                                            </Button>
                                        </TableCell>
                                        {/* Add other cells here as per your columns */}
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {/* Table Pagination */}
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            component="div"
                            count={totalRows || 0}
                            rowsPerPage={pageSize || 0}
                            page={page || 0}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                        />
                    </Paper>
                </div>
            </div>
        </>
    );
}