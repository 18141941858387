import './index.css';
import React, { useEffect, useState, useRef } from "react";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { IconButton, InputAdornment, Link } from "@mui/material";
import axios from "axios";
import config from "../../../../Config/Config";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, getVesselDocumentV2, getVesselDocumentTypeV2, convertBase64, insertUpdateDocumentV2, deleteDocumentByDocumentId } from "../../../../Helpers/ApplicationHelpers";
import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import "../../../AppV2.css";
import { AccountCircle, Key, Login, Visibility, VisibilityOff } from "@mui/icons-material";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import FMSTopBar from "../../../../Components/v2/FMSTopBar";
import FMSTheme from "../../../FMSTheme";
import FMSSidebar from '../../../../Components/v2/FMSSidebar';
import EnhancedTable from '../../../../Components/v2/FMSTable';
import { Trans, useTranslation } from "react-i18next";
import NavBar from "../../../../Components/v2/FMSNavBarMUI";
import FMSAuthenticateToken from '../../../../Components/v2/FMSAuthenticateToken';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import dayjs from 'dayjs';
import { Document, Page, pdfjs } from 'react-pdf';
import { triggerBase64Download } from "../../../../Helpers/Base64Downloader";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import ZoomInIcon from '@mui/icons-material/ZoomIn';

export default function FMSVesselDocumentPage({ state }) {
    const { t, i18n } = useTranslation();
    const [cookies, removeCookie] = useCookies(["token"]);
    const [listVesselDocument, setListVesselDocument] = useState([]);
    const [listVesselDocumentType, setListVesselDocumentType] = useState([]);
    const [selectedDocumentType, setSelectedDocumentType] = useState();
    const [vesselId, setVesselId] = useState();
    const [vesselName, setVesselName] = useState();
    const navigate = useNavigate();
    const [fileDoc, setFileDoc] = useState("");
    const [open, setOpen] = useState(false);
    const [openDetailDocument, setOpenDetailDocument] = useState(false);
    const [documentToBeViewed, setDocumentToBeViewed] = useState();
    const [loadDocType, setLoadDocType] = useState(false);
    const [loadDoc, setLoadDoc] = useState(false);
    const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [zoomFactor, setZoomFactor] = useState(1);
    const [documentIdToDelete, setDocumentIdToDelete] = useState('');
    const [docDetailUpload, setDocDetailUpload] = useState({
        id: "",
        vessel_id: state.vesselId,
        base64_document: "",
        document_name: "",
        document_desc: "",
        created_date: "",
        created_by: "",
        updated_date: "",
        updated_by: "",
        is_active: true,
        reference_number: "",
        document_type_id: "",
        expired_date: null
    });

    const columnsForTable = [
        { id: 'document_name', label: 'Name' },
        { id: 'reference_number', label: 'Reference Number' },
        { id: 'updated_date', label: 'Update Date' },
        { id: 'document_type_name', label: 'Type' },
        { id: 'expired_date', label: 'Expired Date' },
        { id: 'view_button', label: 'View' },
        { id: 'delete_button', label: 'Delete' },
    ];

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        // border: '1px solid #1E3A8A',
        boxShadow: 24,
        borderRadius: 2,
        p: 4,
    };

    const styleForDetailDocument = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '800px',
        height: '90vh',
        bgcolor: 'background.paper',
        // border: '1px solid #1E3A8A',
        boxShadow: 24,
        borderRadius: 2,
        p: 4,
    };

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    useEffect(() => {
        if (state) {
            if (state.vesselId && state.vesselName) {
                setVesselId(state.vesselId);
                setVesselName(state.vesselName);
            }
        }
    }, [state]);

    useEffect(() => {
        if (documentToBeViewed) {
            if (documentToBeViewed.base64_document) {
                console.log(documentToBeViewed)
                handleOpenDetailDocument();
            }
        } else {
            setIsDocumentLoaded(false);
            setNumPages(0);
        }
    }, [documentToBeViewed])

    useEffect(() => {
        console.log(docDetailUpload);
    }, [docDetailUpload])

    useEffect(() => {
        if (documentIdToDelete !== ''){
            if (window.confirm("Are you sure you want to delete?")) {
                deleteDocument();
            }
        }
    }, [documentIdToDelete])

    useEffect(() => {
        if (loadDocType === true) {
            loadVesselDocumentType();
        }
    }, [loadDocType])

    useEffect(() => {
        //loadPhoto();
        if (numPages >= 1) {
            setIsDocumentLoaded(true);
        }
    }, [numPages])

    useEffect(() => {
        if (listVesselDocumentType) {
            if (listVesselDocumentType.length > 0) {
                console.log('listVesselDocumentType', listVesselDocumentType);
                handleOpen();
            }
        }
    }, [listVesselDocumentType])

    useEffect(() => {
        if (vesselId) {
            loadVesselDocument();
        }
    }, [vesselId])

    const deleteDocument = async () => {
        try {
            let result = await deleteDocumentByDocumentId(cookies.token, documentIdToDelete);
            if (result === 0) {
                alert('Document successfully deleted');
                loadVesselDocument();
            } else {
                alert('Delete failed. Please try again');
            }
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const loadVesselDocument = async () => {
        try {
            let result = await getVesselDocumentV2(cookies.token, vesselId);
            // console.log(result);
            setListVesselDocument(result);
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const loadVesselDocumentType = async () => {
        try {
            let result = await getVesselDocumentTypeV2(cookies.token);
            // console.log(result);
            setListVesselDocumentType(result);
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const uploadDocument = async () => {
        try {
            let response = await insertUpdateDocumentV2(cookies.token, docDetailUpload);
            if (response.error_code === 0) {
                alert('Document successfully uploaded');
                loadVesselDocument();
                handleClose();
            } else {
                alert('Upload failed. Please try again');
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const resetDocDetailupload = () => {
        setDocDetailUpload({
            id: "",
            vessel_id: state.vesselId,
            base64_document: "",
            document_name: "",
            document_desc: "",
            created_date: "",
            created_by: "",
            updated_date: "",
            updated_by: "",
            is_active: true,
            reference_number: "",
            document_type_id: "",
            expired_date: null
        });
        setFileDoc("");
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setLoadDocType(false);
        setOpen(false);
        resetDocDetailupload();
    }

    const handleOpenDetailDocument = () => {
        setOpenDetailDocument(true);
    }

    const handleCloseDetailDocument = () => {
        setDocumentToBeViewed({});
        setOpenDetailDocument(false);
    }

    const handleUploadDateChange = (date) => {
        if (date) {
            const formattedDate = dayjs(date).format('YYYY-MM-DD');
            console.log(formattedDate);
            setDocDetailUpload({ ...docDetailUpload, created_date: formattedDate });
        } else {
            setDocDetailUpload({ ...docDetailUpload, created_date: null });
        }
    };

    const handleUploadExpiredDateChange = (date) => {
        if (date) {
            const formattedDate = dayjs(date).format('YYYY-MM-DD');
            setDocDetailUpload({ ...docDetailUpload, expired_date: formattedDate });
        } else {
            setDocDetailUpload({ ...docDetailUpload, expired_date: null });
        }
    };

    const handleChangeDocumentType = (type) => {
        if (type) {
            setDocDetailUpload({ ...docDetailUpload, document_type_id: type.target.value });
        }
    };
    
    const handleRowClick = (row) => {
        setDocumentToBeViewed(row);
    };

    const handleDeleteRow = (id) => {
        setDocumentIdToDelete(id);
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setPageNumber(1);
        setNumPages(numPages);
    }

    const changePage = (offsetPdf) => {
        setPageNumber(prevPageNumber => prevPageNumber + offsetPdf);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }

    return (
        <>
            <div className='mainContainerFMS'>
                <div className='childMainContainerFMS'>
                    <div className='childMainContainerFMS-BODYDoc'>
                        <div className='docAddButtonContainer'>
                            <Button variant="outlined"
                                startIcon={<NoteAddIcon />}
                                onClick={() => {
                                    setLoadDocType(true);
                                }}>
                                Add New Document
                            </Button>
                        </div>
                        <div className='fmsEnhancedTableContainer' >
                            <EnhancedTable data={listVesselDocument} columns={columnsForTable} onRowClick={handleRowClick} deletedId={handleDeleteRow} />
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ width: '100%' }}>
                        ADD DOCUMENT
                    </Typography>
                    <hr />
                    <Button
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<NoteAddIcon />}
                        sx={{ width: '100%', height: '50px', mb: 2 }}
                    >
                        Select Document
                        <VisuallyHiddenInput
                            type="file"
                            onChange={async (e) => {
                                if (e.target.files[0].type === "application/pdf") {
                                    setFileDoc(e.target.value)
                                    let base64Doc = await convertBase64(e.target.files[0]);
                                    const base64DocString = base64Doc.toString();
                                    setDocDetailUpload({ ...docDetailUpload, base64_document: base64DocString });

                                } else {
                                    setFileDoc("");
                                    setDocDetailUpload({ ...docDetailUpload, base64_document: '' });
                                    alert("Please choose another file");
                                }
                            }
                            }
                        />
                    </Button>
                    {
                        fileDoc !== "" ? (<TextField
                            value={fileDoc !== "" ? fileDoc && fileDoc.split('\\').pop() : 'Picture'}
                            disabled
                            id="outlined-basic"
                            onChange={(e) => {
                                // setDetailVesselData({ ...detailVesselData, loa: e.target.value });
                            }}
                            sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                },
                                width: '100%',
                                mb: 1
                            }}
                        />) : (<></>)
                    }

                    <TextField
                        value={docDetailUpload.document_name}
                        id="outlined-basic"
                        label="Document Name"
                        onChange={(e) => {
                            setDocDetailUpload({ ...docDetailUpload, document_name: e.target.value });
                        }}
                        sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                            },
                            width: '100%',
                            mb: 1
                        }}
                    />
                    <TextField
                        value={docDetailUpload.reference_number}
                        id="outlined-basic"
                        label="Reference Number"
                        onChange={(e) => {
                            setDocDetailUpload({ ...docDetailUpload, reference_number: e.target.value });
                        }}
                        sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                            },
                            width: '100%',
                            mb: 1
                        }}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Document Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={listVesselDocument.document_type_name}
                            label="Document Type"
                            onChange={handleChangeDocumentType}
                        >
                            {listVesselDocumentType && listVesselDocumentType.length > 0 ? (
                                listVesselDocumentType.map((value) => (
                                    <MenuItem key={value.id} value={value.id}>
                                        {value.document_type_name}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem value="" disabled>
                                    No options available
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker
                                label="Upload Date"
                                sx={{ width: '100%' }}
                                slotProps={{
                                    actionBar: {
                                        actions: ['clear', 'today'],
                                    },
                                }}
                                onChange={handleUploadDateChange} />
                            <DatePicker
                                label="Expired Date"
                                sx={{ width: '100%' }}
                                slotProps={{
                                    actionBar: {
                                        actions: ['clear', 'today'],
                                    },
                                }}
                                onChange={handleUploadExpiredDateChange} />
                        </DemoContainer>
                    </LocalizationProvider>
                    <TextField
                        value={docDetailUpload.document_desc}
                        multiline={true}
                        rows={2}
                        id="outlined-basic"
                        label="Description"
                        onChange={(e) => {
                            setDocDetailUpload({ ...docDetailUpload, document_desc: e.target.value });
                        }}
                        sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                            },
                            width: '100%',
                            mt: 1
                        }}
                    />
                    <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                        <Button variant="outlined" startIcon={<CheckBoxIcon />}
                            sx={{ width: '100%', height: '50px' }}
                            onClick={() => { uploadDocument() }}>
                            Save
                        </Button>
                        <Button variant="outlined" startIcon={<DisabledByDefaultIcon />}
                            sx={{ width: '100%', height: '50px' }}
                            onClick={() => { handleClose() }}>
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Modal>

            <Modal
                open={openDetailDocument}
                onClose={handleCloseDetailDocument}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50%',
                        height: '90%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        overflow: 'hidden',
                    }}
                >
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {documentToBeViewed && documentToBeViewed.document_name}
                    </Typography>
                    <hr />
                    <div
                        style={{
                            width: '100%',
                            height: '870px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            overflow: 'auto',
                            border: '1px solid #ddd',
                            backgroundColor: '#f9f9f9',
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            padding: 0
                        }}
                    >
                    {/* <div style={{width: '100%', border: '1px black solid'}}> */}
                    <Document
                            file={documentToBeViewed && documentToBeViewed.base64_document}
                            onLoadSuccess={onDocumentLoadSuccess}
                            loading={<div>Loading document...</div>}
                            style={{width: '100%'}}
                        >
                            <Page
                                pageNumber={pageNumber}
                                scale={zoomFactor}
                                width={600}
                                height={849}
                                renderAnnotationLayer={false}
                                renderTextLayer={false}
                            />
                        </Document>
                    </div>
                    {
                        isDocumentLoaded === true ? (
                                <div style={{ marginTop: '20px', textAlign: 'center' }}>
                                    <p>
                                        Page {pageNumber} of {numPages}
                                    </p>
                                    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                                        <Button
                                            variant="contained"
                                            disabled={pageNumber <= 1}
                                            onClick={previousPage}
                                        >
                                            Previous Page
                                        </Button>
                                        <Button
                                            variant="contained"
                                            disabled={pageNumber >= numPages}
                                            onClick={nextPage}
                                        >
                                            Next Page
                                        </Button>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '10px' }}>
                                        <Button
                                            variant="outlined"
                                            disabled={zoomFactor <= 0.2}
                                            onClick={() => setZoomFactor(zoomFactor * 0.8)}
                                        >
                                            Zoom Out
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            onClick={() => setZoomFactor(1)}
                                        >
                                            Reset Zoom
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            disabled={zoomFactor >= 2}
                                            onClick={() => setZoomFactor(zoomFactor * 1.2)}
                                        >
                                            Zoom In
                                        </Button>
                                    </div>
                                </div>
                        ) : (<></>)
                    }
                </Box>
            </Modal>
        </>
    );
}