import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, } from "../../../Helpers/ApplicationHelpers";
import "../../AppV2.css";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import FMSTheme from "../../FMSTheme";
import { Trans, useTranslation } from "react-i18next";
import NavBar from "../../../Components/v2/FMSNavBarMUI";
import FMSAuthenticateToken from "../../../Components/v2/FMSAuthenticateToken";

export default function FMSAnalyticsPage() {
    const { t, i18n } = useTranslation();
    const [cookies, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate();

    useEffect(() => {
        
    }, []);



    return (
        <>
            <div className='mainContainerFMS'>
                <div className='childMainContainerFMS'>
                    <div className='childMainContainerFMS-BODY'>

                    </div>
                </div>
            </div>
        </>
    );
}