import './index.css'
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid, findAllVesselById, convertBase64, insertUpdateVesselPhoto, insertUpdateGeneralSpecification, deleteImgVessel, updateImgVesselCover } from "../../../../Helpers/ApplicationHelpers";
import "../../../AppV2.css";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import FMSTheme from "../../../FMSTheme";
import { Trans, useTranslation } from "react-i18next";
import NavBar from "../../../../Components/v2/FMSNavBarMUI";
import { Box, TextField, Typography, Modal, Stack, Button } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import noImg from "../../../../Assets/Images/FMS/vessel_fms_icon.png";
import EditIcon from '@mui/icons-material/Edit';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/Delete';

export default function FMSVesselDetailPage({ state }) {
    const { t, i18n } = useTranslation();
    const [cookies, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    let location = useLocation();
    const [vesselId, setVesselId] = useState();
    const [open, setOpen] = useState(false);
    const [imgToShow, setImgToShow] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [isEditPict, setIsEditPict] = useState(false);
    const [isEditParticulars, setIsEditParticulars] = useState(false);
    const [fileImg, setFileImg] = useState("");
    const [imgIdToRemove, setImgIdToRemove] = useState();
    const [imgIdToCover, setImgIdToCover] = useState();
    const [pictDetailUpload, setPictDetailUpload] = useState({
        vessel_id: state.vesselId,
        base64Img: "",
        description: "",
        created_date: "",
        updated_date: "",
        created_by: "",
        updated_by: "",
        is_panorama: true,
        takenDate: "",
        name : "",
    });
    const [detailVesselData, setDetailVesselData] = useState({
        vessel_id: "",
        vessel_name: "",
        customer_name: "",
        vessel_type_id: "",
        vessel_type_name: "",
        imo_number: "",
        loa: "",
        bmo: "",
        dmo: "",
        draft_extream: "",
        fuel_tank_capacities: "",
        fresh_water_tank_capacities: "",
        power: "",
        speed: "",
        bollard: "",
        complement: "",
        deadweight: "",
        gross_tonnage: "",
        image_list: []
    });


    useEffect(() => {
        setVesselId(state.vesselId);
    }, []);

    useEffect(() => {
        if (imgIdToRemove){
            if (window.confirm("Are you sure you want to delete?")) {
                removePicture();
            }
        }
    }, [imgIdToRemove])

    useEffect(() => {
        if (imgIdToCover){
            if (window.confirm("Choose Image for a Thumbnail?")) {
                updateCoverPicture();
            }
        }
    }, [imgIdToCover])

    useEffect(() => {
        if (vesselId) {
            loadVesselDetail();
        }
    }, [vesselId])

    useEffect(() => {
        if (detailVesselData) {
            console.log(detailVesselData)
        }
    }, [detailVesselData])

    useEffect(() => {
        console.log(pictDetailUpload);
    }, [pictDetailUpload])


    const loadVesselDetail = async () => {
        try {
            let results = await findAllVesselById(cookies.token, vesselId);
            if (results) {
                console.log(results)
                setDetailVesselData({
                    ...detailVesselData,
                    vessel_id: results.id,
                    vessel_name: results.vessel_name,
                    customer_name: results.customer_name,
                    vessel_type_id: results.vessel_type_id,
                    vessel_type_name: results.vessel_type_name,
                    imo_number: results.imo_number,
                    loa: results.length_over_all,
                    bmo: results.bmld,
                    dmo: results.hmld,
                    draft_extream: results.draft_extream,
                    fuel_tank_capacities: results.fuel_tank_capacities,
                    fresh_water_tank_capacities: results.fresh_water_tank_capacities,
                    power: results.power,
                    speed: results.speed,
                    bollard: results.bollard_pull,
                    complement: results.complement,
                    deadweight: results.deadweight_tonnage,
                    gross_tonnage: results.gross_register_tonnage,
                    image_list: results.image_list,
                })
            }
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        // border: '1px solid #1E3A8A',
        boxShadow: 24,
        borderRadius: 2,
        p: 4,
    };

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        resetPictDetailupload()
    }

    const handleUploadDateChange = (date) => {
        if (date) {
            const formattedDate = dayjs(date).format('YYYY-MM-DD');
            console.log(formattedDate);
            setPictDetailUpload({ ...pictDetailUpload, takenDate: formattedDate });
        }
    };

    const resetPictDetailupload = () => {
        setPictDetailUpload({
            vessel_id: state.vesselId,
            base64Img: "",
            description: "",
            created_date: "",
            updated_date: "",
            created_by: "",
            updated_by: "",
            is_panorama: true,
            takenDate: "",
            name : "",
        });
        setFileImg("");
    }

    const uploadPicture = async () => {
        try {
            let response = await insertUpdateVesselPhoto(cookies.token, pictDetailUpload, vesselId);
            if (response === 0) {
                alert('Picture successfully uploaded');
            } else {
                alert('Upload failed. Please try again');
            }
            loadVesselDetail();
            handleClose();
        } catch (exception) {
            console.log(exception);
        }
    }

    const removePicture = async () => {
        try {
            let response = await deleteImgVessel(cookies.token, imgIdToRemove);
            if (response === 0) {
                alert('Picture successfully deleted');
                loadVesselDetail();
                handleClose();
            } else {
                alert('Delete failed. Please try again');
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const updateCoverPicture = async () => {
        try {
            let response = await updateImgVesselCover(cookies.token, imgIdToCover, vesselId);
            if (response === 0) {
                alert('Update successfull');
                loadVesselDetail();
                handleClose();
            } else {
                alert('Update failed. Please try again');
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    const submitDetail = async () => {
        try {
            let response = await insertUpdateGeneralSpecification(cookies.token, detailVesselData, vesselId);
            // console.log(response)
            if (response.error_code === 0) {
                alert('Detail updated successfully');
            } else {
                alert('Update failed. Please try again');
            }
            loadVesselDetail();
            handleClose();
        } catch (exception) {
            console.log(exception);
        }
    }





    return (
        <>
            <div className='mainContainerFMS'>
                <div className='childMainContainerFMS'>
                    <div className='childMainContainerFMS-BODY overflow-auto'>
                        <Box component="fieldset" sx={{ p: 2, border: '1px solid #1E3A8A', borderRadius: '5px', width: '100%' }}>
                            <div className='titleContainerForVesselDetail'>
                                <legend>
                                    <Typography variant="h5" color='black'>
                                        GENERAL SPECIFICATION
                                    </Typography>
                                </legend>
                                {
                                    isEdit === true ? (<Stack direction="row" spacing={2} sx={{ mr: 2 }}>
                                        <Button variant="outlined" startIcon={<CheckBoxIcon />}
                                            onClick={() => { 
                                                setIsEdit(false);
                                                submitDetail();
                                             }}>
                                            Save
                                        </Button>
                                        <Button variant="outlined" startIcon={<DisabledByDefaultIcon />}
                                            onClick={() => { setIsEdit(false) }}>
                                            Cancel
                                        </Button>
                                    </Stack>) : (
                                        <Stack direction="row" spacing={2} sx={{ mr: 2 }}>
                                            <Button variant="outlined" startIcon={<EditIcon />}
                                                onClick={() => { setIsEdit(true) }}>
                                                Edit
                                            </Button>
                                        </Stack>)
                                }
                            </div>
                            <Box
                                component="form"
                                sx={{ '& > :not(style)': { m: 1, width: '45ch' } }}
                                noValidate
                                autoComplete="off"
                            >
                                <TextField
                                    disabled
                                    value={detailVesselData.vessel_name}
                                    label="Name"
                                    onChange={(e) => {
                                        setDetailVesselData({ ...detailVesselData, vessel_name: e.target.value });
                                    }}
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#000000",
                                        },
                                    }}
                                />
                                <TextField
                                    disabled
                                    value={detailVesselData.customer_name}
                                    id="outlined-basic"
                                    label="Owner"
                                    onChange={(e) => {
                                        setDetailVesselData({ ...detailVesselData, customer_name: e.target.value });
                                    }}
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#000000",
                                        },
                                    }}
                                />
                                <TextField
                                    disabled
                                    value={detailVesselData.vessel_type_name}
                                    id="outlined-basic"
                                    label="Type"
                                    onChange={(e) => {
                                        setDetailVesselData({ ...detailVesselData, vessel_type_name: e.target.value });
                                    }}
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#000000",
                                        },
                                    }}
                                />
                                <TextField
                                    disabled={!isEdit}
                                    value={detailVesselData.imo_number}
                                    id="outlined-basic"
                                    label="IMO Number"
                                    onChange={(e) => {
                                        setDetailVesselData({ ...detailVesselData, imo_number: e.target.value });
                                    }}
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#000000",
                                        },
                                    }}
                                />
                            </Box>

                            <Box
                                component="form"
                                sx={{ '& > :not(style)': { m: 1, width: '45ch' } }}
                                noValidate
                                autoComplete="off"
                            >
                                <TextField
                                    disabled={!isEdit}
                                    value={isNaN(detailVesselData.loa) ? 0 : detailVesselData.loa === null ? "" : detailVesselData.loa}
                                    id="outlined-basic"
                                    label="Length O.A (M)"
                                    onChange={(e) => {
                                        setDetailVesselData({ ...detailVesselData, loa: parseFloat(e.target.value) });
                                    }}
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#000000",
                                        },
                                    }}
                                />
                                <TextField
                                    disabled={!isEdit}
                                    value={isNaN(detailVesselData.bmo) ? 0 : detailVesselData.bmo === null ? "" : detailVesselData.bmo}
                                    id="outlined-basic"
                                    label="Breadth Moulded (M)"
                                    onChange={(e) => {
                                        setDetailVesselData({ ...detailVesselData, bmo: parseFloat(e.target.value) });
                                    }}
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#000000",
                                        },
                                    }}
                                />
                                <TextField
                                    disabled={!isEdit}
                                    value={isNaN(detailVesselData.dmo) ? 0 : detailVesselData.dmo === null ? "" : detailVesselData.dmo}
                                    id="outlined-basic"
                                    label="Depth Moulded (M)"
                                    onChange={(e) => {
                                        setDetailVesselData({ ...detailVesselData, dmo: parseFloat(e.target.value) });
                                    }}
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#000000",
                                        },
                                    }}
                                />
                                <TextField
                                    disabled={!isEdit}
                                    value={isNaN(detailVesselData.draft_extream) ? 0 : detailVesselData.draft_extream === null ? "" : detailVesselData.draft_extream}
                                    id="outlined-basic"
                                    label="Draft Extreme (M)"
                                    onChange={(e) => {
                                        setDetailVesselData({ ...detailVesselData, draft_extream: parseFloat(e.target.value) });
                                    }}
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#000000",
                                        },
                                    }}
                                />
                            </Box>

                            <Box
                                component="form"
                                sx={{ '& > :not(style)': { m: 1, width: '45ch' } }}
                                noValidate
                                autoComplete="off"
                            >
                                <TextField
                                    disabled={!isEdit}
                                    value={isNaN(detailVesselData.fuel_tank_capacities) ? 0 : detailVesselData.fuel_tank_capacities === null ? "" : detailVesselData.fuel_tank_capacities}
                                    id="outlined-basic"
                                    label="Fuel Tanks Capacities (Cub. M)"
                                    onChange={(e) => {
                                        setDetailVesselData({ ...detailVesselData, fuel_tank_capacities: parseFloat(e.target.value) });
                                    }}
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#000000",
                                        },
                                    }}
                                />
                                <TextField
                                    disabled={!isEdit}
                                    value={isNaN(detailVesselData.fresh_water_tank_capacities) ? 0 : detailVesselData.fresh_water_tank_capacities === null ? "" : detailVesselData.fresh_water_tank_capacities}
                                    id="outlined-basic"
                                    label="Fresh Water Tank Capacities (Cub. M)"
                                    onChange={(e) => {
                                        setDetailVesselData({ ...detailVesselData, fresh_water_tank_capacities: parseFloat(e.target.value) });
                                    }}
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#000000",
                                        },
                                    }}
                                />
                                <TextField
                                    disabled={!isEdit}
                                    value={detailVesselData.power}
                                    id="outlined-basic"
                                    label="Power (PS)"
                                    onChange={(e) => {
                                        setDetailVesselData({ ...detailVesselData, power: e.target.value });
                                    }}
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#000000",
                                        },
                                    }}
                                />
                                <TextField
                                    disabled={!isEdit}
                                    value={detailVesselData.speed}
                                    id="outlined-basic"
                                    label="Speed (Knots)"
                                    onChange={(e) => {
                                        setDetailVesselData({ ...detailVesselData, speed: e.target.value });
                                    }}
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#000000",
                                        },
                                    }}
                                />
                            </Box>

                            <Box
                                component="form"
                                sx={{ '& > :not(style)': { m: 1, width: '45ch' } }}
                                noValidate
                                autoComplete="off"
                            >
                                <TextField
                                    disabled={!isEdit}
                                    value={isNaN(detailVesselData.bollard) ? 0 : detailVesselData.bollard === null ? "" : detailVesselData.bollard}
                                    id="outlined-basic"
                                    label="Bollard Pull (Ton)"
                                    onChange={(e) => {
                                        setDetailVesselData({ ...detailVesselData, bollard: parseFloat(e.target.value) });
                                    }}
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#000000",
                                        },
                                    }}
                                />
                                <TextField
                                    disabled={!isEdit}
                                    value={isNaN(detailVesselData.complement) ? 0 : detailVesselData.complement === null ? "" : detailVesselData.complement}
                                    id="outlined-basic"
                                    label="Complement (Persons)"
                                    onChange={(e) => {
                                        setDetailVesselData({ ...detailVesselData, complement: parseFloat(e.target.value) });
                                    }}
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#000000",
                                        },
                                    }}
                                />
                                <TextField
                                    disabled={!isEdit}
                                    value={isNaN(detailVesselData.deadweight) ? 0 : detailVesselData.deadweight === null ? "" : detailVesselData.deadweight}
                                    id="outlined-basic"
                                    label="Deadweight (Ton)"
                                    onChange={(e) => {
                                        setDetailVesselData({ ...detailVesselData, deadweight: parseFloat(e.target.value) });
                                    }}
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#000000",
                                        },
                                    }}
                                />
                                <TextField
                                    disabled={!isEdit}
                                    value={isNaN(detailVesselData.gross_tonnage) ? 0 : detailVesselData.gross_tonnage === null ? "" : detailVesselData.gross_tonnage}
                                    id="outlined-basic"
                                    label="Gross Tonnage (Ton)"
                                    onChange={(e) => {
                                        setDetailVesselData({ ...detailVesselData, gross_tonnage: parseFloat(e.target.value) });
                                    }}
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#000000",
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                        <div style={{ margin: '10px' }}></div>
                        <Box component="fieldset" sx={{ p: 2, border: '1px solid #1E3A8A', borderRadius: '5px', width: '100%' }}>
                            <div className='titleContainerForVesselDetail'>
                                <legend>
                                    <Typography variant="h5" color='black'>
                                        PICTURES
                                    </Typography>
                                </legend>
                                {
                                    isEditPict === true ? (<Stack direction="row" spacing={2} sx={{ mr: 2 }}>
                                        <Button variant="outlined" startIcon={<CheckBoxIcon />}
                                            onClick={() => { setIsEditPict(false) }}>
                                            Save
                                        </Button>
                                        <Button variant="outlined" startIcon={<DisabledByDefaultIcon />}
                                            onClick={() => { setIsEditPict(false) }}>
                                            Cancel
                                        </Button>
                                    </Stack>) : (
                                        <Stack direction="row" spacing={2} sx={{ mr: 2 }}>
                                            <Button variant="outlined" startIcon={<EditIcon />}
                                                onClick={() => { setIsEditPict(true) }}>
                                                Edit
                                            </Button>
                                        </Stack>)
                                }
                            </div>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap', paddingBottom: '30px' }}>
                                {
                                    detailVesselData && detailVesselData.image_list && detailVesselData.image_list.map((img, index) => {
                                        return (
                                            <>
                                                {
                                                    // isEditPict === true ? (
                                                    //     <div style={{ height: '200px', width: '20px', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                                    //         <Button variant="text"
                                                    //             sx={{ width: '10px', height: '10px' }}
                                                    //             onClick={() => { setImgIdToRemove(img.id)}}>
                                                    //             <DisabledByDefaultIcon />
                                                    //         </Button>
                                                    //     </div>) : (<></>)
                                                }
                                                {
                                                    img && img.base64_image !== null ? (<div style={{ width: '200px', height: '200px', border: '1px #bebebe solid', margin: '5px', borderRadius: '5px' }}
                                                        // onClick={setImgToShow(img)}
                                                        >
                                                            <LazyLoadImage
                                                                src={img.base64_image&& img.base64_image}
                                                                style={{
                                                                    display: { xs: 'none', md: 'flex' },
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    objectFit: 'cover',
                                                                    padding: 5,
                                                                }}
                                                            />
                                                            {
                                                                isEditPict === true ? (<div style={{width: '100%', height: '40px', marginTop: '3px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                                    <Button variant="outlined"
                                                                sx={{ width: '100%', height: '100%', flex:1, mr: 2 }}
                                                                onClick={() => { setImgIdToRemove(img.id)}}>
                                                                <DeleteIcon />
                                                            </Button>
                                                            {
                                                                img && img.is_cover === true ? (<Button variant="contained"
                                                                    sx={{ width: '100%', height: '100%', flex:3 }}
                                                                    onClick={() => {setImgIdToCover(img.id) }}>
                                                                    Thumbnail
                                                                </Button>) : (<Button variant="outlined"
                                                                    sx={{ width: '100%', height: '100%', flex:3 }}
                                                                    onClick={() => {setImgIdToCover(img.id) }}>
                                                                    Thumbnail
                                                                </Button>)
                                                            }
                                                                    </div>) : (<></>)
                                                }
                                                        </div>) : (<></>)
                                                }
                                            </>

                                        )
                                    })
                                }
                                {
                                    isEditPict === true ? (<div
                                        style={{ width: '200px', height: '200px', border: '1px #bebebe solid', margin: '5px', borderRadius: '5px' }}
                                    // onClick={setImgToShow(img)}
                                    >
                                        <Button variant="outlined"
                                            sx={{ width: '100%', height: '100%' }}
                                            onClick={handleOpen}>
                                            <AddPhotoAlternateIcon />
                                        </Button>
                                    </div>) : (<></>)
                                }
                            </div>

                        </Box>
                        <div style={{ margin: '10px' }}></div>
                        <Box component="fieldset" sx={{ p: 2, border: '1px solid #1E3A8A', borderRadius: '5px', width: '100%' }}>
                            <legend>
                                <Typography variant="h5" color='black'>
                                    SHIP PARTICULARS
                                </Typography>
                            </legend>
                            <Box
                                component="form"
                                sx={{ '& > :not(style)': { m: 1, width: '45ch' } }}
                                noValidate
                                autoComplete="off"
                            >
                                <Box
                                    component="form"
                                    sx={{ '& > :not(style)': { m: 1, width: '45ch' } }}
                                    noValidate
                                    autoComplete="off"
                                ></Box>
                                <Box
                                    component="form"
                                    sx={{ '& > :not(style)': { m: 1, width: '45ch' } }}
                                    noValidate
                                    autoComplete="off"
                                ></Box>
                                <Box
                                    component="form"
                                    sx={{ '& > :not(style)': { m: 1, width: '45ch' } }}
                                    noValidate
                                    autoComplete="off"
                                ></Box>
                                <Box
                                    component="form"
                                    sx={{ '& > :not(style)': { m: 1, width: '45ch' } }}
                                    noValidate
                                    autoComplete="off"
                                ></Box>
                            </Box>
                        </Box>
                    </div>
                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ width: '100%' }}>
                        ADD PICTURE
                    </Typography>
                    <hr />
                    <div
                        style={{ width: '200px', height: '200px', border: '1px #bebebe solid', margin: '10px 20%', borderRadius: '5px' }}
                    // onClick={setImgToShow(img)}
                    >
                        <LazyLoadImage
                            src={pictDetailUpload && pictDetailUpload.base64Img ? pictDetailUpload.base64Img : noImg}
                            style={{
                                display: { xs: 'none', md: 'flex' },
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                padding: 5,
                            }}
                        />
                    </div>
                    <Button
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<AddPhotoAlternateIcon />}
                        sx={{ width: '100%', height: '50px', mb: 2 }}
                    >
                        Select Picture
                        <VisuallyHiddenInput
                            type="file"
                            onChange={async (e) => {
                                if (e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png") {
                                    setFileImg(e.target.value)
                                    let base64Img = await convertBase64(e.target.files[0]);
                                    const base64ImgString = base64Img.toString();
                                    setPictDetailUpload({ ...pictDetailUpload, base64Img: base64ImgString });

                                } else {
                                    setFileImg("");
                                    setPictDetailUpload({ ...pictDetailUpload, base64Img: "" });
                                    alert("Please choose another file");
                                }



                            }
                            }
                        />
                    </Button>
                    {
                        fileImg !== "" ? (<TextField
                            value={fileImg !== "" ? fileImg && fileImg.split('\\').pop() : 'Picture'}
                            disabled
                            id="outlined-basic"
                            onChange={(e) => {
                                // setDetailVesselData({ ...detailVesselData, loa: e.target.value });
                            }}
                            sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "#000000",
                                },
                                width: '100%',
                                mb: 1
                            }}
                        />) : (<></>)
                    }

                    <TextField
                        value={pictDetailUpload.label}
                        id="outlined-basic"
                        label="Label"
                        onChange={(e) => {
                            setPictDetailUpload({ ...pictDetailUpload, name: e.target.value });
                        }}
                        sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                            },
                            width: '100%'
                        }}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker label="Upload Date" sx={{ width: '100%' }}
                                onChange={handleUploadDateChange} />
                        </DemoContainer>
                    </LocalizationProvider>
                    <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                        <Button variant="outlined" startIcon={<CheckBoxIcon />}
                            sx={{ width: '100%', height: '50px' }}
                            onClick={() => { uploadPicture() }}>
                            Save
                        </Button>
                        <Button variant="outlined" startIcon={<DisabledByDefaultIcon />}
                            sx={{ width: '100%', height: '50px' }}
                            onClick={() => { handleClose() }}>
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
}