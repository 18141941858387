import './index.css';
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import "../../../../../AppV2.css";

import { Trans, useTranslation } from "react-i18next";


export default function AdminVesselEquipment({state}) {
    const { t, i18n } = useTranslation();
    const [cookies, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const vesselId = state.vesselId;


    return (
        <>
            <div className='mainContainerFMS'>
                <div className='childMainContainerFMS overflow-auto'>
                    <h2>Vessels</h2>'
                    
                </div>
            </div>
        </>
    );
}