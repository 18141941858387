import "../../../../src/App.css";
import { Spinner } from 'react-bootstrap';
import Lottie from 'react-lottie';
import animationData from '../../../Assets/Images/FMS/LottiesJSON/vessel.json';


function Loading({
    loading,
    loadingText
}) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <>
            {
                loading &&
                <div style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 999,
                }}>
                    <div style={{
                        display: "flex",
                        flex: 1,
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        backgroundColor: "rgba(244,249,255)",
                        boxShadow: '5px 5px #d0dcec',
                        top: window.innerHeight / 3,
                        position: "absolute",
                        width: "10%",
                        // height: "20%",
                        borderRadius: 10
                    }}>

                        <div style={{
                            flex: 1,
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: 0,
                        }}>
                            <Lottie
                                options={defaultOptions}
                                height={'100%'}
                                width={'100%'}
                                style={{ pointerEvents: 'none' }}
                            />
                        </div>
                        <div style={{ flex: 1, }}>
                            <p style={{
                                fontWeight: "bold",
                                fontSize: 24,
                                color: "#2f71bd"
                            }}>
                                {loadingText}
                            </p>
                        </div>

                    </div> </div >
            }
        </>
    )
}

export default Loading;