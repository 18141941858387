import './index.css'
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useCookies } from "react-cookie";
import "../../../AppV2.css";
import { Card, CardContent, CardHeader, Divider, Grid2, Icon, Modal, Stack, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { Box, TextField, Paper } from "@mui/material";

import { Container } from 'react-bootstrap';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';

import { getSMSEquipmentV2 } from '../../../../Helpers/ApplicationHelpers';
import { getInventoryMasterParameter } from '../../../../Helpers/ApplicationHelpers';

export default function FMSVesselEquipmentPage({ state }) {
    const [cookies, removeCookie] = useCookies(["token"]);
    const [vesselId, setVesselId] = useState(state.vesselId);
    const [smsEquipment, setSMSEquipment] = useState();
    const [openModal, setOpenModal] = useState(false);

    const [parameters, setParameters] = useState([]);
    const [details, setDetails] = useState({});
    const [loading, setLoading] = useState(false);

    const styleModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        // border: '1px solid #1E3A8A',
        boxShadow: 24,
        borderRadius: 2,
        p: 4,
    };

    useEffect(() => {

    }, []);

    useEffect(() => {
        loadVesselSMSEquipment();
    }, [vesselId]);


    const loadVesselSMSEquipment = async () => {
        try {
            const results = await getSMSEquipmentV2(cookies.token, vesselId);
            const mapped = {};
            results.forEach((r) => {
                if(!mapped.hasOwnProperty(r.equipment_category_id)){
                    mapped[r.equipment_category_id] = {category_name: r.equipment_category_name, position: {}};
                }
                if(!mapped[r.equipment_category_id].position.hasOwnProperty(r.vessel_equipment_position)){
                    mapped[r.equipment_category_id].position[r.vessel_equipment_position] = [];
                }
                mapped[r.equipment_category_id].position[r.vessel_equipment_position].push(r);
            });
            console.log("MAPPED : ", mapped);
            setSMSEquipment(mapped);
        }catch(exception) {
            console.error(exception);
        }
    }

    const capitalizeWords = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
            .join(' ');
    };

    const loadParameter = async (inventoryMasterId) => {
        setLoading(true);
        try {
            const results = await getInventoryMasterParameter(cookies.token, inventoryMasterId);
            setParameters(results);
        }catch(exception) {
            console.error(exception);
        }
        setLoading(false);
    }

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        setOpenModal(false);
    }

    return (
        <>
            <div className='mainContainerFMS'>
                <div className='childMainContainerFMS'>
                    <div className='childMainContainerFMS-BODY overflow-auto'>
                        <br />
                        <Container>
                            {smsEquipment && Object.values(smsEquipment).map((r) => {
                                return <Card sx={{marginBottom: '15px'}}>
                                    <CardHeader
                                        title={capitalizeWords(r.category_name || "")}
                                    />
                                    <CardContent>
                                        {Object.keys(r.position).map((key) => {
                                            const data = r.position[key];
                                            console.log("KEY", key);
                                            console.log("DATA", data);
                                             return <Accordion className="">
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1-content"
                                                    id="panel1-header"
                                                >
                                                    {capitalizeWords(key)}
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <TableContainer component={Paper}>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell><strong>Name</strong></TableCell>
                                                                    <TableCell><strong>Running Hour</strong></TableCell>
                                                                    <TableCell><strong>Serial Number</strong></TableCell>
                                                                    <TableCell><strong>Commissioned Date</strong></TableCell>
                                                                    <TableCell><strong>Parameter Detail</strong></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {data.map((d) => {
                                                                    return <TableRow>
                                                                        <TableCell>{d.brand_name} {d.inventory_master_name}</TableCell>
                                                                        <TableCell>{d.running_hour}</TableCell>
                                                                        <TableCell>{d.serial_number}</TableCell>
                                                                        <TableCell>{d.commissioned_date}</TableCell>
                                                                        <TableCell><Button onClick={() => {
                                                                            loadParameter(d.inventory_master_id);
                                                                            setDetails(d);
                                                                            handleOpenModal();
                                                                        }}><Icon size={40} color="#64748B">launch</Icon></Button></TableCell>
                                                                    </TableRow>
                                                                })}
                                                                
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </AccordionDetails>
                                            </Accordion>
                                        })}

                                    </CardContent>
                                </Card> 
                            })}     
                        </Container>
                        <Modal
                            open={openModal}
                            onClose={handleCloseModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={styleModal}>
                                <Typography className="pb-3" id="modal-modal-title" variant="h6" component="h2" sx={{ width: '100%' }}>
                                    DETAILS
                                </Typography>
                                <Grid2 container className="pb-5" spacing={4}>
                                    <Grid2 size={6}>
                                        <TextField
                                            disabled
                                            value={details?.brand_name}
                                            label="Brand Name"
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                },
                                            }}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <TextField
                                            disabled
                                            value={details?.inventory_master_name}
                                            label="Inventory Master Name"
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                },
                                            }}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <TextField
                                            disabled
                                            value={details?.inventory_group_name}
                                            label="Group"
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                },
                                            }}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <TextField
                                            disabled
                                            value={details?.inventory_subgroup_name}
                                            label="Subgroup"
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                },
                                            }}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <TextField
                                            disabled
                                            value={details?.serial_number}
                                            label="Serial Number"
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                },
                                            }}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <TextField
                                            disabled
                                            value={details?.commissioned_date}
                                            label="Commissioned Date"
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                },
                                            }}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <TextField
                                            disabled
                                            value={details?.running_hour}
                                            label="Running Hour"
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#000000",
                                                },
                                            }}
                                        />
                                    </Grid2>
                                </Grid2>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><strong>Parameter Name</strong></TableCell>
                                                <TableCell><strong>Value</strong></TableCell>
                                                <TableCell><strong>Description</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {parameters.map((d) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell>{d.parameter_name}</TableCell>
                                                        <TableCell>{d.parameter_value}</TableCell>
                                                        <TableCell>{d.parameter_description}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Modal>
                    </div>
                </div>
            </div>

        </>
    );
}
