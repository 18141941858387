import './index.css';
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Chart from 'react-apexcharts';

export default function GaugeComponent({ value, size, minValue, maxValue, label, valueUnit }) {
    const [series, setSeries] = useState([0]);

    const options = {
        chart: {
            height: 350,
            type: 'radialBar',
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            radialBar: {
                startAngle: -135,
                endAngle: 225,
                hollow: {
                    margin: 0,
                    size: '70%',
                    background: '#e6edf9',
                    dropShadow: {
                        enabled: true,
                        top: 3,
                        left: 0,
                        blur: 4,
                        opacity: 0.5,
                    },
                },
                track: {
                    background: '#fff',
                    strokeWidth: '67%',
                    dropShadow: {
                        enabled: true,
                        top: -3,
                        left: 0,
                        blur: 4,
                        opacity: 0.35,
                    },
                },
                dataLabels: {
                    show: true,
                    name: {
                        offsetY: -10,
                        color: '#888',
                        fontSize: '17px',
                    },
                    value: {
                        formatter: (val) => `${parseInt(value)} ${valueUnit}`,  // Display real value as integer
                        color: '#111',
                        fontSize: '20px',
                    },
                },
            },
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 0.5,
                gradientToColors: ['#2563EB'],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],  // Gradient will be from 0 to 100%
            },
        },
        stroke: {
            lineCap: 'square',
        },
        labels: [label],
    };

    useEffect(() => {
        const sanitizedValue = isNaN(value) || value == null ? 0 : value;

        // Normalize the value as a percentage of the range between minValue and maxValue
        // Formula to scale the value between 0 and 100
        const normalizedValue = ((sanitizedValue - minValue) / (maxValue - minValue)) * 100;

        // Set the normalized value to the series, ensuring it's between 0 and 100
        setSeries([Math.min(Math.max(normalizedValue, 0), 100)]);
    }, [value, minValue, maxValue]);

    return (
        <Box
            sx={{
                borderRadius: '50%',
                padding: '0px',
                width: size,
                height: size,
            }}
        >
            <Chart options={options} series={series} type="radialBar" height={size} />
        </Box>
    );
}
