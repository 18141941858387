// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.childMainContainerFMS-BODYDoc{
    width: 100%;
    height: 85vh;
    max-height: 88vh;
    /* border: 2px red solid; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 5px;
    flex: 1 1;
}

.docAddButtonContainer{
    width: 100%;
    /* border: 1px red solid; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/v2/Pages/FMSVessel/VesselDocument/index.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,2BAA2B;IAC3B,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,uBAAuB;IACvB,gBAAgB;IAChB,SAAO;AACX;;AAEA;IACI,WAAW;IACX,2BAA2B;IAC3B,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":[".childMainContainerFMS-BODYDoc{\n    width: 100%;\n    height: 85vh;\n    max-height: 88vh;\n    /* border: 2px red solid; */\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: flex-start;\n    padding-top: 5px;\n    flex: 1;\n}\n\n.docAddButtonContainer{\n    width: 100%;\n    /* border: 1px red solid; */\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    padding-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
