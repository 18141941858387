import React, { useEffect, useState, useRef } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { IconButton, InputAdornment, Link } from "@mui/material";
import axios from "axios";
import config from "../../../../Config/Config";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { isTokenValid } from "../../../../Helpers/ApplicationHelpers";
import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import "../../../AppV2.css";
import { AccountCircle, Key, Login, Visibility, VisibilityOff } from "@mui/icons-material";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import FMSTheme from "../../../FMSTheme";

export default function FMSAdminLoginPage() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [loginButtonDisabled, setLoginButtonDisabled] = useState(true);
    const [cookies, setCookie] = useCookies(["token", "userId", "userName", "userEmail"]);
    const [errorMessage, setErrorMessage] = useState("");
    const txtUsername = useRef(null);
    const txtPassword = useRef(null);

    const navigate = useNavigate();

    const qLogo = require("./../../../../Assets/Images/logo-quantum-white.png");

    useEffect(() => {
        async function checkCookies() {
            if (cookies.token) {
                let isAuthenticated = await isFMSAdminTokenValid(cookies.token);
                if (isAuthenticated)
                    navigate("/FMS/Admin/AdminDashboard");
            } else {
                navigate("/FMS/Admin");
            }
        }
        checkCookies();
        let interval = setInterval(() => {
            if (txtPassword.current) {
                setPassword(txtPassword.current.value);
                clearInterval(interval);
            }
        }, 100);
        let interval2 = setInterval(() => {
            if (txtUsername.current) {
                setUsername(txtUsername.current.value)
                clearInterval(interval2)

            }
        }, 100);

    }, []);

    useEffect(() => {
        checkUsernamePasswordLength();
    }, [username]);

    useEffect(() => {
        checkUsernamePasswordLength();
    }, [password]);

    const doLogin = async () => {
        try {
            let response = await axios.post(`${config.API_ENDPOINT}/api/UserAccount/v2/FMSAdminLogin`, {
                username: username,
                password: password
            });
            console.log(response);
            if (response.data.error_code === 0) {
                let loginData = response.data.data;
                loginData.loging_as = 'FMSAdmin'
                setCookie("token", loginData.token, { path: "/" });
                setCookie("userId", loginData.user_account_id, { path: "/" });
                setCookie("userName", loginData.login_username, { path: "/" });
                setCookie("userEmail", loginData.email, { path: "/" });
                setCookie("languageId", loginData.language_id, { path: "/" });
                setCookie("roleId", loginData.role_id, { path: "/" });
                setCookie("level", loginData.user_level, { path: "/" });
                setCookie("logingAs", loginData.loging_as, { path: "/" });
                setCookie("extCustomerId", loginData.ext_customer_id, { path: "/" });


                navigate("/FMS/Admin/Dashboard");
            } else {
                setErrorMessage("Failed to sign in, wrong username and/or password.");
            }

        }
        catch (exception) {
            console.log(exception);
            setErrorMessage("Error has been occurred, please contact system administrator");
        }
    }

    const checkUsernamePasswordLength = () => {
        if (username.length > 0 && password.length > 0)
            setLoginButtonDisabled(false);
        else
            setLoginButtonDisabled(true);
    }

    return (
        <>
            <ThemeProvider theme={FMSTheme}>
                <div className="login-container" id="fms-login-root">
                    <div className="login-left-side">
                        <div style={{
                            display: "flex",
                            alignContent: "flex-start",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            flexDirection: "column",
                            width: "100%",
                            paddingLeft: 200,
                            paddingTop: 220,
                        }}>
                            <img src={qLogo}></img>
                            <div style={{
                                paddingTop: 25
                            }}>
                                <h2 style={{
                                    color: "white",
                                    fontFamily: "Roboto"
                                }}>QUANTUM PESONA DUNIA</h2>
                            </div>
                            <div>
                                <h4 style={{
                                    color: "#F59E0B",
                                    fontFamily: "Roboto",
                                    fontStyle: "italic",
                                    fontWeight: "bold"
                                }}>Innovating the Future</h4>
                            </div>
                            <div style={{
                                paddingTop: 25
                            }}>
                                <h5 style={{
                                    color: "white",
                                    fontFamily: "Roboto"
                                }}>Empowering sustainable and efficient maritime operation</h5>
                            </div>
                        </div>

                    </div>
                    <div className="login-right-side">
                        <div style={{
                            display: "flex",
                            flex: 1,
                            flexWrap: "wrap",
                            flexDirection: "column",
                            paddingLeft: 200,
                            paddingTop: 200,

                        }}>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexWrap: "wrap",
                                backgroundColor: "#FFFFFF",
                                flexDirection: "column",
                                maxHeight: 450,
                                maxWidth: "70%",
                                borderRadius: 10,
                                alignContent: "flex-start",
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    alignSelf: "center",
                                    width: "100%",
                                    flexDirection: "column",
                                    flex: 1,
                                    paddingTop: 30,
                                    paddingLeft: 15,
                                    paddingRight: 15
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",

                                    }}>
                                        <p style={{
                                            fontWeight: "bold",
                                            fontFamily: "Roboto",
                                            fontSize: 20,
                                            color: "black"
                                        }}>ADMINISTRATION AREA</p>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        flexDirection: "column"
                                    }}>
                                        <p style={{
                                            fontWeight: "bold",
                                            fontFamily: "Roboto",
                                            fontSize: 16,
                                            color: "red"
                                        }}>Administrator Login Page, Authorized Personnel Only!</p>
                                        <p style={{
                                            fontWeight: "bold",
                                            fontFamily: "Roboto",
                                            fontSize: 12,
                                            color: "black",
                                            textAlign: "center"
                                        }}>If you are a FMS customer, click<span> </span>
                                        <Link
                                            component="button"
                                            variant="body2"
                                            onClick={() => {
                                                navigate("/FMS")
                                            }}
                                        >HERE</Link><span> </span>to return to customer login page.</p>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        width: "100%",
                                        paddingTop: 30
                                    }}>
                                        <TextField
                                            fullWidth
                                            required
                                            value={username}
                                            id="outlined-required"
                                            label="Username"
                                            defaultValue=""
                                            onChange={(e) => {
                                                setUsername(e.target.value);
                                                checkUsernamePasswordLength();
                                            }}
                                            slotProps={{
                                                input: {
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <AccountCircle />
                                                        </InputAdornment>
                                                    ),
                                                },
                                            }}
                                        />
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        width: "100%",
                                        paddingTop: 30
                                    }}>
                                        <TextField
                                            fullWidth
                                            required
                                            value={password}
                                            id="outlined-password-input"
                                            label="Password"
                                            defaultValue=""
                                            type={!showPassword ? "password" : "text"}
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                                checkUsernamePasswordLength();
                                            }}
                                            slotProps={{
                                                input: {
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Key />
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {showPassword &&
                                                                <IconButton onClick={() => {
                                                                    setShowPassword(!showPassword);
                                                                }} aria-label="visibility-off" color="primary">
                                                                    <VisibilityOff />
                                                                </IconButton>}
                                                            {!showPassword &&
                                                                <IconButton onClick={() => {
                                                                    setShowPassword(!showPassword);
                                                                }} aria-label="visibility" color="primary">
                                                                    <Visibility />
                                                                </IconButton>}
                                                        </InputAdornment>
                                                    )
                                                },
                                            }}
                                        />
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        width: "100%",
                                        paddingTop: 30
                                    }}>
                                        <Button
                                            onClick={()=>{
                                                doLogin();
                                            }}
                                            disabled={loginButtonDisabled}
                                            startIcon={<Login />} style={{
                                                width: "100%"
                                            }} variant="contained">
                                            Login
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </ThemeProvider>
        </>
    );
}