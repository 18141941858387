import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const FMSAdminAuthenticate = ({ children, params = {} }) => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState();
    const [isAuthenticationFinish, setIsAuthenticationFinish] = useState(false);

    const {
        validationFunc,
        validationData,
        navigateURL = "/FMS",
    } = params;

    useEffect(() => {
        const checkAuthentication = async () => {
            if (validationFunc) {
                const isValidated = await validationFunc(validationData);
                if (!isValidated) {
                    console.log("User is not authenticated!");
                    setIsAuthenticated(false);
                }
                setIsAuthenticated(true);
                setIsAuthenticationFinish(true);
            } else {
                console.warn("Validation function is not provided!");
            }
        };

        checkAuthentication();
    }, []);

    useEffect(() => {
        if(isAuthenticated === false){
            navigate(navigateURL);
        }

    }, [isAuthenticated])

    return (
        <div>
            {!isAuthenticated && <div style={{
                display: "flex",
                flex: 1,
            }}></div>}
            {isAuthenticated && children}
        </div>
    );
};

export default FMSAdminAuthenticate;