// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.titleContainerForVesselDetail{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px black solid; */
}`, "",{"version":3,"sources":["webpack://./src/v2/Pages/FMSVessel/VesselDetail/index.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,6BAA6B;AACjC","sourcesContent":[".titleContainerForVesselDetail{\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    /* border: 1px black solid; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
