// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collapseContainerVesselTracking{
    width: 350px;
    height: 400px;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    padding: 10px;
}

.popupContainerVesselTracking{
    width: 250px;
    /* height: 400px; */
    /*background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    padding: 10px;
    */
}

.collapseContainerVesselTrackingBody{
    /* border: 1px black solid; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.formGroupVesselTrackingFMS{
    width: 100%;
    margin: 5px 3px;
}

.loadingContainerVesselMapFMS{
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center; 
    align-items: center; 
    z-index: 9999; 
}`, "",{"version":3,"sources":["webpack://./src/v2/Pages/FMSVessel/VesselTracking/index.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,0CAA0C;IAC1C,2CAA2C;IAC3C,kBAAkB;IAClB,0BAA0B;IAC1B,uBAAuB;IACvB,sBAAsB;IACtB,qBAAqB;IACrB,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB;;;;;;;;KAQC;AACL;;AAEA;IACI,6BAA6B;IAC7B,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB","sourcesContent":[".collapseContainerVesselTracking{\n    width: 350px;\n    height: 400px;\n    background-color: rgba(255, 255, 255, 0.9);\n    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);\n    border-radius: 5px;\n    -webkit-border-radius: 5px;\n    -moz-border-radius: 5px;\n    -ms-border-radius: 5px;\n    -o-border-radius: 5px;\n    padding: 10px;\n}\n\n.popupContainerVesselTracking{\n    width: 250px;\n    /* height: 400px; */\n    /*background-color: rgba(255, 255, 255, 0.9);\n    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);\n    border-radius: 5px;\n    -webkit-border-radius: 5px;\n    -moz-border-radius: 5px;\n    -ms-border-radius: 5px;\n    -o-border-radius: 5px;\n    padding: 10px;\n    */\n}\n\n.collapseContainerVesselTrackingBody{\n    /* border: 1px black solid; */\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n}\n\n.formGroupVesselTrackingFMS{\n    width: 100%;\n    margin: 5px 3px;\n}\n\n.loadingContainerVesselMapFMS{\n    position: fixed; \n    top: 0;\n    left: 0;\n    width: 100%;\n    display: flex;\n    justify-content: center; \n    align-items: center; \n    z-index: 9999; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
