import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";


/////v2 here
import FMSLoginPage from "./v2/Pages/FMSLogin/index.js";
import FMSContainerPage from "./v2/Pages/FMSContainerPage/index.js";
import FMSDashboardPage from "./v2/Pages/FMSDashboard/index.js";
import FMSVesselPage from "./v2/Pages/FMSVessel/index.js";
import FMSMapPage from "./v2/Pages/FMSMap/index.js";
import FMSMaintenancePage from "./v2/Pages/FMSMaintenance/index.js";
import FMSCrewPage from "./v2/Pages/FMSCrew/index.js";
import FMSInventoryPage from "./v2/Pages/FMSInventory/index.js";
import FMSAnalyticsPage from "./v2/Pages/FMSAnalytics/index.js";
import FMSVesselDetailPage from "./v2/Pages/FMSVessel/VesselDetail/index.js";
import FMSVesselTrackingPage from "./v2/Pages/FMSVessel/VesselTracking/index.js";
import FMSVesselSMSPage from "./v2/Pages/FMSVessel/VesselSMS/index.js";
import FMSEnginePerformancePage from "./v2/Pages/FMSVessel/VesselSMS/EnginePerformance/index.js";

/////v2 FMS Admin
import FMSAdminLogin from "./v2/Admin/Pages/FMSAdminLogin/index.js";
import AdminCustomerPage from "./v2/Admin/Pages/Customers/index.js";
import AdminProductPage from "./v2/Admin/Pages/Products/index.js";
import AdminVesselPage from "./v2/Admin/Pages/Vessels/index.js";
import AdminContainerPage from "./v2/Admin/Pages/FMSAdminContainerPage/index.js";
import FMSVesselSMSDashboard from "./v2/Pages/FMSVessel/VesselSMSDashboard/index.js";


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<FMSLoginPage />} />
        
        <Route path="/FMS" element={<FMSContainerPage />} />
        <Route path="/FMS/Dashboard" element={<FMSDashboardPage />} />
        <Route path="/FMS/Vessel" element={<FMSVesselPage />} />
        <Route path="/FMS/Map" element={<FMSMapPage />} />
        <Route path="/FMS/Maintenance" element={<FMSMaintenancePage />} />
        <Route path="/FMS/Crew" element={<FMSCrewPage />} />
        <Route path="/FMS/Inventory" element={<FMSInventoryPage />} />
        <Route path="/FMS/Analytics" element={<FMSAnalyticsPage />} />
        <Route path="/FMS/Vessel/VesselDetail" element={<FMSVesselDetailPage />} />
        <Route path="/FMS/Vessel/VesselTracking" element={<FMSVesselTrackingPage />} />
        <Route path="/FMS/Vessel/VesselSMS" element={<FMSVesselSMSPage />} />
        <Route path="/FMS/Vessel/VesselSMS/EnginePerformance" element={<FMSEnginePerformancePage />} />
        <Route path="/FMS/Vessel/Dashboard" element={<FMSVesselSMSDashboard />}/>
        
        
        <Route path="/FMS/Admin" element={<FMSAdminLogin />} />
        <Route path="/FMS/Admin/Dashboard" element={<AdminContainerPage />} />
        {/* <Route path="/FMS/Admin/Customers" element={<AdminCustomerPage />} />
        <Route path="/FMS/Admin/Products" element={<AdminProductPage />} />
        <Route path="/FMS/Admin/Vessels" element={<AdminVesselPage />} /> */}
      </Routes>
    </BrowserRouter>
  );
}
