import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Loading from '../FMSLoading';

// TODO : Fix this authenticate component
const FMSAuthenticate = ({ children, params = {} }) => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [isAuthenticationFinish, setIsAuthenticationFinish] = useState(false);
    const [toRender, setToRender] = useState(<><Loading loading={true} loadingText={"Loading..."}/></>);
    const {
        validationFunc,
        validationData,
        navigateURL = "/FMS",
    } = params;

    useEffect(() => {
        const checkAuthentication = async () => {
            if (validationFunc) {
                const isValidated = await validationFunc(validationData);
                if (!isValidated) {
                    console.log("User is not authenticated!");
                    setIsAuthenticated(false);
                }else{
                    setIsAuthenticated(true);
                }
                setIsAuthenticationFinish(true);
                // setIsAuthenticationFinish(true);
            } else {
                console.warn("Validation function is not provided!");
            }
        };

        checkAuthentication();
    }, [validationFunc, validationData, navigateURL, navigate]);

    useEffect(() => {
        if(isAuthenticated === false && isAuthenticationFinish === true){
            navigate(navigateURL);
        }
    }, [isAuthenticated, isAuthenticationFinish])
    

    return (
        <div>
            {isAuthenticated && children}
        </div>
    );
};

export default FMSAuthenticate;