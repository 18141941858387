import { Line } from "react-chartjs-2";
import { Chart } from "chart.js";
import { useState, useEffect } from "react";
import moment from "moment";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function DynamicLineChartDoubleAxis({
  chartData: { firstDataset, secondDataset },
  xAxisLabels,
  chartLimits: {
    y1: { min: y1Min, max: y1Max, division: division1 },
    y2: { min: y2Min, max: y2Max, division: division2 },
  },
  labels: { firstLabel, secondLabel },
}) {

  console.log("DIVISION1", division1);
  console.log("DIVISION2", division2);


  const notAvailable = ['N/A'];
  const padding = 0.3;

  let min1 = Number(y1Min || 0);
  let max1 = Number(y1Max || 0);
  if(min1 == 0 && max1 == 0){
      max1 = 100;
  }

  max1 = max1 + (max1 * padding);
  let y1StepSize = Math.max(Math.round((max1 - min1)/division1), 1);

  let min2 = Number(y2Min || 0);
  let max2 = Number(y2Max || 0);
  if(min2 == 0 && max2 == 0){
      max2 = 100;
  }
  max2 = max2 + (max2 * padding);
  let y2StepSize = Math.max(Math.round((max2 - min2)/division2), 1);

  const lengthAxisLabel = xAxisLabels.length;

  const synchronizedData1 = firstDataset.slice(0, lengthAxisLabel);
  const synchronizedData2 = secondDataset.slice(0, lengthAxisLabel);

  const data1ToShow = synchronizedData1.length ? synchronizedData1: notAvailable;
  const data2ToShow = synchronizedData2.length ? synchronizedData2 : notAvailable;
  const axisLabelsToShow = xAxisLabels.length ? xAxisLabels : notAvailable;

  const [dataFirst, setDataFirst] = useState([]);
  const [dataSecond, setDataSecond] = useState([]);

  useEffect(() => setDataFirst(data1ToShow), [firstDataset]);
  useEffect(() => setDataSecond(data2ToShow), [secondDataset]);
  
  const y1Data = dataFirst;
  const y2Data = dataSecond;

  const chartData = {
    labels: axisLabelsToShow,
    datasets: [
      {
        label: firstLabel,
        data: y1Data,
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        borderColor: "#d6b300",
        borderWidth: 2,
        yAxisID: "y1",
      },
      {
        label: secondLabel,
        data: y2Data,
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        borderColor: "#16a34a",
        borderWidth: 2,
        yAxisID: "y2",
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: true,
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#031e67",
        },
      },
      y1: {
        min: min1,
        max: max1,
        ticks: {
          stepSize: y1StepSize,
          color: "#d6b300",
        },
        position: "left",
      },
      y2: {
        min: min2,
        max: max2,
        ticks: {
          stepSize: y2StepSize,
          color: "#16a34a",
        },
        position: "right",
      },
    },
    elements: {
      line: {
        tension: 0.5,
      },
    },
    animation: false,
  };

  return <Line data={chartData} options={chartOptions} />;
}
