import './index.css';
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import "../../../../AppV2.css";
import { Trans, useTranslation } from "react-i18next";

import { getVesselV2 } from '../../../../../Helpers/ApplicationHelpers';

import EnhancedTableAdmin from '../../../../../Components/v2/FMSTableAdminForVessel';
export default function AdminCustuomerVesselsPage({state, setPageToDisplay}) {
    const { t, i18n } = useTranslation();
    const [cookies, removeCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const customerId = state.customerId;

    const [listVessel, setListVessel] = useState([]);

    const columnsForTable = [
        { id: 'img_button', label: '' },
        { id: 'sms_serial_number', label: 'Serial Number' },
        { id: 'vessel_name', label: 'Vessel Name' },
        { id: 'vessel_type_name', label: 'Vessel Type' },
        // { id: 'class_register_number', label: 'Class' },
        { id: 'imo_number', label: 'IMO Number' },
        { id: 'vessel_equipment', label: 'Vessel Equipment' },
    ];

    useEffect(() => {
        loadVessel();
    }, []);

    useEffect(() => {
        console.log('list vessel', listVessel);
    }, [listVessel])

    const loadVessel = async () => {
        try {
            let result = await getVesselV2(
                cookies.token, cookies.languageId, customerId
            );
            setListVessel(result);
        }
        catch (exception) {
            console.log(exception);
        }
    }


    return (
        <>
            <div className='mainContainerFMS'>
                <div className='childMainContainerFMS overflow-auto'>
                    <h2>Vessels</h2>'
                    <EnhancedTableAdmin data={listVessel} columns={columnsForTable} setPageToDisplay={setPageToDisplay}/>
                </div>
            </div>
        </>
    );
}