import "./index.css";
import * as React from 'react';
import {
    Icon,
    AppBar, Box, Toolbar, IconButton, Typography, List, Divider, ListItem, ListItemButton,
    ListItemIcon, ListItemText,
    Menu, Container, Avatar, Button, Tooltip, MenuItem, CssBaseline, Drawer
} from "@mui/material";
import { AdbIcon, NotificationsNoneOutlined, InfoOutlined, MenuOutlined, Inbox, Mail, Speed } from "@mui/icons-material"
import { useCookies } from "react-cookie";
import { useNavigate, NavLink, Navigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { Notifications } from "@mui/icons-material";
import Clock from 'react-live-clock';
import { isTokenValid, getCompanyLogo } from "../../../../Helpers/ApplicationHelpers";
import { getAuthorizedAdminMenuV2 } from '../../../../Helpers/AccountHelpers';
import FMSAdminAuthenticateToken from "../../../../Components/v2/FMSAdminAuthenticateToken";
import qLogo from "./../../../../Assets/Images/logo-quantum-white.png";
import AdminDashboardPage from "../AdminDashboard";
import AdminCustomerPage from "../Customers";
import AdminProductPage from "../Products";
import AdminVesselPage from "../Vessels";

function AdminContainerPage() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [cookies, setCookie, removeCookie] = useCookies();
    const [pages, setPages] = useState([]);
    const [listMenu, setListMenu] = useState([]);
    const [settings, setSettings] = useState(['Profile', 'Account', 'Dashboard', 'Logout']);
    const [companyLogo, setCompanyLogo] = useState();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
    const [pageToDisplay, setPageToDisplay] = useState(<AdminDashboardPage />);
    

    useEffect(() => {
        //loadCompanyLogo();
        loadAuthorizedMenu();
    }, []);



    useEffect(() => {
        console.log('listmenu', listMenu);
        if (listMenu) {
            let menuArr = [];
            for (let i = 0; i < listMenu.length; i++) {
                menuArr.push(listMenu[i].menu_name);
            }
            setPages(menuArr);
        }
    }, [listMenu]);

    useEffect(()=>{
        switch (selectedMenuIndex) {
            case 0:
                setPageToDisplay(<AdminDashboardPage />);
                break;
            case 1:
                setPageToDisplay(<AdminCustomerPage setPageToDisplay={setPageToDisplay}/>);
                break;
            case 2:
                setPageToDisplay(<AdminProductPage />);
                break;
            case 3:
                setPageToDisplay(<AdminVesselPage />);
                break;
            default:
                setPageToDisplay(<AdminDashboardPage />);
                break;
        }
    }, [selectedMenuIndex]);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };


    const loadAuthorizedMenu = async () => {
        try {
            let result = await getAuthorizedAdminMenuV2(cookies.token, cookies.userId);
            console.log(result);
            setListMenu(result);
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const logOut = async () => {
        try {
            handleCloseUserMenu();
            if (window.confirm("Are you sure you want to logout?")) {
                const removeAllCookies = () => {
                    // Iterate over all cookie names and remove them
                    Object.keys(cookies).forEach((cookieName) => {
                        removeCookie(cookieName);
                    });
            
                    console.log("All cookies removed!");
                };

                removeAllCookies();
                navigate(cookies.logingAs === 'User' ? "/FMS/Admin" : "/FMS/Admin");
            }
        } catch (exception) {
            console.log(exception);
        }
    }


    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const stringToColor = (string) => {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
            Tesdt
        </Box>
    );

    return (
        <div id="fms-root">
            <Box sx={{ display: 'flex', backgroundColor: "#FFFFFF" }}>
                <CssBaseline />
                <AppBar position='fixed' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Container maxWidth="xl">
                        <Toolbar disableGutters>
                            <img
                                src={qLogo}
                                alt="Quantum Logo"
                                style={{
                                    display: { xs: 'none', md: 'flex' },
                                    width: 40,
                                    height: 40,
                                    marginRight: 8,
                                }}
                            />
                            <Typography
                                variant="h6"
                                noWrap
                                component="a"
                                href="#app-bar-with-responsive-menu"
                                sx={{
                                    mr: 2,
                                    display: { xs: 'none', md: 'flex' },
                                    fontFamily: 'Inter',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                FMS ADMINISTRATION
                            </Typography>

                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <MenuOutlined />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{ display: { xs: 'block', md: 'none' } }}
                                >
                                    {pages && pages.map((page) => (
                                        <MenuItem key={page} onClick={handleCloseNavMenu}>
                                            <Typography sx={{ textAlign: 'center' }}>{page}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                {/* {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page}
                            </Button>
                        ))} */}
                            </Box>
                            <Box sx={{ flexGrow: 0 }}>
                                <Typography
                                    variant="h6"
                                    noWrap
                                    // component="a"
                                    href="#app-bar-with-responsive-menu"
                                    sx={{
                                        mr: 2,
                                        display: { xs: 'none', md: 'flex' },
                                        fontFamily: 'Inter',
                                        color: 'inherit',
                                        textDecoration: 'none',
                                        fontSize: "small"
                                    }}
                                >
                                    <Clock
                                        format={'YYYY/MM/DD hh:mm A'}
                                        ticking={true}
                                        timezone={'Asia/Jakarta'}
                                    />
                                </Typography>
                            </Box>
                            <Box sx={{ flexGrow: 0 }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <NotificationsNoneOutlined />
                                </IconButton>
                            </Box>
                            <Box sx={{ flexGrow: 0 }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <InfoOutlined />
                                </IconButton>
                            </Box>
                            <Box sx={{ flexGrow: 0 }}>
                                <Typography
                                    variant="h6"
                                    noWrap
                                    href="#app-bar-with-responsive-menu"
                                    sx={{
                                        mr: 2,
                                        display: { xs: 'none', md: 'flex' },
                                        fontFamily: 'Inter',
                                        color: 'inherit',
                                        textDecoration: 'none',
                                        fontSize: "small"
                                    }}
                                >
                                    {cookies.userName}
                                </Typography>
                            </Box>
                            <Box sx={{ flexGrow: 0 }}>

                                <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        <Avatar sx={{
                                            bgcolor: stringToColor(cookies.userName),
                                        }} alt="Remy Sharp">{cookies.userName.slice(0, 1)}</Avatar>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    <MenuItem onClick={handleCloseUserMenu}><Typography sx={{ textAlign: 'center' }}>Profile</Typography></MenuItem>
                                    <MenuItem onClick={handleCloseUserMenu}><Typography sx={{ textAlign: 'center' }}>My account</Typography></MenuItem>
                                    <MenuItem onClick={handleCloseUserMenu}></MenuItem>
                                    <MenuItem onClick={logOut}><Typography sx={{ textAlign: 'center' }}>Log Out</Typography></MenuItem>
                                </Menu>
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
                <Drawer
                    variant="permanent"
                    sx={{
                        width: 100,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: 100, boxSizing: 'border-box' },
                    }}
                >
                    <Toolbar />
                    <Box sx={{ overflow: 'auto' }}>
                        <List>
                            {
                                listMenu && listMenu.map((menu, index) => {
                                    return (
                                        <Box key={index}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: 98,
                                                height: 98,
                                                borderRadius: 1,
                                                bgcolor: selectedMenuIndex === index ? "primary.dark" : "primary.white",
                                                '&:hover': {
                                                    bgcolor: '#1E3A8A',
                                                },
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                // setOpen(true);
                                                //navigate(`/FMS${menu.menu_path}`)
                                                setSelectedMenuIndex(index);
                                            }}
                                        >
                                            <Icon size={40} color="#64748B">{menu.menu_icon}</Icon>
                                            <Typography
                                                variant="h6"
                                                noWrap
                                                sx={{
                                                    textAlign: "center",
                                                    display: { xs: 'none', md: 'flex' },
                                                    fontFamily: 'Inter',
                                                    color:selectedMenuIndex === index ? "primary.white" : "#64748B",
                                                    '&:hover': {
                                                        color: 'primary.white',
                                                    },
                                                    textDecoration: 'none',
                                                    fontSize: "small"
                                                }}
                                            >
                                                {menu.menu_name}
                                            </Typography>
                                        </Box>
                                    )
                                })
                            }
                        </List>
                        <Divider />

                    </Box>
                </Drawer>
                <Box sx={{
                    flex: 1,
                    width: "100%",
                    backgroundColor: "#FFFFFF"
                }}>
                    <FMSAdminAuthenticateToken>
                        {
                           pageToDisplay
                        } 
                        
                    </FMSAdminAuthenticateToken>
                </Box>
            </Box>

        </div>


    );
}
export default AdminContainerPage;
