// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customScrollbarTabs {
    overflow-y: auto;
    max-height: 100vh;
}


.customScrollbarTabs::-webkit-scrollbar {
    width: 5px;
}

.customScrollbarTabs::-webkit-scrollbar-thumb {
    background-color: #7da2f1;
    border-radius: 20px;
    border: 3px solid #7da2f1;
}

.customScrollbarTabs::-webkit-scrollbar-track {
    background-color: #f4f4f4;
    border-radius: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/v2/Pages/FMSVessel/VesselSMSDashboard/index.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;;AAGA;IACI,UAAU;AACd;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;AACvB","sourcesContent":[".customScrollbarTabs {\n    overflow-y: auto;\n    max-height: 100vh;\n}\n\n\n.customScrollbarTabs::-webkit-scrollbar {\n    width: 5px;\n}\n\n.customScrollbarTabs::-webkit-scrollbar-thumb {\n    background-color: #7da2f1;\n    border-radius: 20px;\n    border: 3px solid #7da2f1;\n}\n\n.customScrollbarTabs::-webkit-scrollbar-track {\n    background-color: #f4f4f4;\n    border-radius: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
